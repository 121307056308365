import React from 'react'
import PageLayout from '../../../layoutcontainers/PageLayout'
import { Button } from '@mui/material'

const Employeeonboarding = () => {
    return (
        <PageLayout>
            <Button variant="contained">Contained</Button>
        </PageLayout>
    )
}

export default Employeeonboarding
