import { DataGrid, GridPagination } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { Box, Chip, CircularProgress, Icon, LinearProgress, Stack } from '@mui/material';
import MaterialBox from '../MaterialBox';
import MaterialInput from '../MaterialInput';
import SearchIcon from '@mui/icons-material/Search';
import CustomizedPagination from './components/CustomPagination';
import MaterialTypography from '../MaterialTypography';
import { Info } from '@mui/icons-material';
function CustomPagination(props) {
    return <GridPagination ActionsComponent={CustomizedPagination} {...props} />;
}

const MaterialDataGrid = ({
    rows,
    columns,
    checkboxSelection,
    onRowClick,
    customHeight,
    loading,
    localeText,
    isSelectable
}) => {

    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([])
    const [hasSearched, setHasSearched] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        if (searchText && searchText.length > 1) {
            const timeoutId = setTimeout(() => {
                getSearchDetails(searchText)
            });
            return () => clearTimeout(timeoutId);
        }
        else {
            return
        }
    }, [searchText])

    const scrolltoId = (id) => {
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
    }

    const getSearchDetails = async (searchText, page) => {
        setSearchResults([])
        setIsLoading(true)
        scrolltoId("search")
        if (!hasSearched) setHasSearched(true)
    }

    // const columnsData = columns.map((column) => {
    //     return ({
    //         field: column.field,
    //         headerName: <MaterialTypography variant="caption" color="black" fontWeight='mediumn'
    //             sx={({ typography: { size } }) => ({
    //                 fontSize: size.sm
    //             })}
    //         >
    //             {column.headerName}
    //         </MaterialTypography>,
    //         flex: column.flex,
    //         width: column.width,
    //         // disableColumnMenu: true,
    //         sortable: true,
    //         renderCell: (params) => (
    //             column.type === "status" ?
    //                 <Stack direction="row" alignItems="center" textAlign='center' gap={1} mt={1.5}>
    //                     <Chip label={params.row.status?.label}
    //                         size="small"
    //                         icon={params.row.status?.icon || <Info />}
    //                         color={params.row.status?.color}
    //                     />
    //                 </Stack>
    //                 :
    //                 column.type === "images" ?
    //                     <>
    //                         <img className='image' src={params.row.image} />
    //                     </>
    //                     :
    //                     <MaterialTypography variant="caption" color="dark"
    //                         sx={({ typography: { size } }) => ({
    //                             fontSize: size.sm
    //                         })}
    //                     >
    //                         {params.row[column.field]}
    //                     </MaterialTypography>
    //         )
    //     })
    // })

    const columnsData = columns.map((column) => ({
        field: column.field,
        headerName: typeof column.headerName === "string" 
            ? column.headerName 
            : column.field, 
        renderHeader: column.headerName && typeof column.headerName === "string" ? () => (
            <MaterialTypography variant="caption" color="black" fontWeight='medium'
                sx={({ typography: { size } }) => ({ fontSize: size.sm })}
            >
                {column.headerName}
            </MaterialTypography>
        ) : undefined,
        flex: column.flex,
        width: column.width,
        sortable: true,
        renderCell: (params) => (
            column.type === "status" ? (
                <Stack direction="row" alignItems="center" textAlign='center' gap={1} mt={1.5}>
                    <Chip label={params.row.status?.label}
                          size="small"
                          icon={params.row.status?.icon || <Info />}
                          color={params.row.status?.color}
                    />
                </Stack>
            ) : column.type === "images" ? (
                <img className='image' src={params.row.image} alt="column image" />
            ) : (
                <MaterialTypography variant="caption" color="dark"
                    sx={({ typography: { size } }) => ({ fontSize: size.sm })}
                >
                    {params.row[column.field]}
                </MaterialTypography>
            )
        ),
    }));
    

    return (
        <MaterialBox>
            {/* <MaterialBox width="16rem" ml="auto" p={1}>
                <MaterialInput
                    
                    autoFocus
                    label="Search"
                    placeholder="Search..."
                    fullWidth
                    
                    variant="outlined"
                    size="medium"
                    icon={isLoading ? {
                        direction: "right",
                        component: (
                            <CircularProgress color="success" size={18} />
                        )
                    } : {
                        direction: "right",
                        component: (
                            <Icon color="action" fontSize="small">
                                <SearchIcon />
                            </Icon>
                        )
                    }}
                />
            </MaterialBox> */}
            <Box style={{ height: customHeight ? customHeight : 400, width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={columnsData}
                    style={{
                        border: 0,
                        cursor: onRowClick && ("pointer"),
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 8 
                            }
                        }
                    }}
                    slots={{
                        // pagination: CustomPagination,
                        loadingOverlay: (props) => (
                            <MaterialBox style={{ overflow: "hidden" }}>
                                <LinearProgress
                                    color="dark"
                                    {...props}
                                />
                            </MaterialBox>
                        )
                    }}
                    loading={loading}
                    checkboxSelection={checkboxSelection}
                    disableRowSelectionOnClick
                    // rowSelectionModel={false}
                    rowsPerPageOptions={[25, 50, 100]}
                    onRowClick={onRowClick}
                    isSelectable={isSelectable}
                    // getRowHeight={() => 'auto'}
                    density="standard"
                    // disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '1px',
                            height: '1px'
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            borderRadius: '5px'
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            backgroundColor: 'grey.400',
                            borderRadius: '10px'
                        },
                        //if isSelectable is true
                        ...(!isSelectable && {
                            '&  .MuiDataGrid-cell': {
                                //focus color
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        })
                    }
                    }
                    disableSelectionOnClick
                    disablePagination
                    localeText={localeText}
                />
            </Box>
            {/* <Box style={{ height: customHeight ? customHeight : 400, width: "100%" }}>
                <DataGrid
                    rows={rows}
                    columns={columnsData}
                    style={{
                        border: 0,
                        cursor: onRowClick && ("pointer"),
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 8
                            }
                        }
                    }}
                    slots={{
                        pagination: CustomPagination,
                        loadingOverlay: (props) => (
                            <MaterialBox style={{ overflow: "hidden" }}>
                                <LinearProgress
                                    color="dark"
                                    {...props}
                                />
                            </MaterialBox>
                        )
                    }}
                    loading={loading}
                    checkboxSelection={checkboxSelection}
                    disableRowSelectionOnClick
                    // rowSelectionModel={false}
                    rowsPerPageOptions={[25, 50, 100]}
                    onRowClick={onRowClick}
                    isSelectable={isSelectable}
                    // getRowHeight={() => 'auto'}
                    density="standard"
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '1px',
                            height: '1px'
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            borderRadius: '5px'
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            backgroundColor: 'grey.400',
                            borderRadius: '10px'
                        },
                        //if isSelectable is true
                        ...(!isSelectable && {
                            '&  .MuiDataGrid-cell': {
                                //focus color
                                '&:focus': {
                                    outline: 'none',
                                },
                            },
                        })
                    }
                    }
                    disableSelectionOnClick
                    localeText={localeText}
                />
            </Box> */}
        </MaterialBox>
    )
}

// Typechecking props for the MaterialDataGrid
MaterialDataGrid.propTypes = {
    rows: PropTypes.number,
    columns: PropTypes.number,
    checkboxSelection: PropTypes.bool,
    isSelectable: PropTypes.bool,
    loading: PropTypes.bool,
    isSelectable: PropTypes.bool,
    onRowClick: PropTypes.func,
    localeText: PropTypes.object,
};

export default MaterialDataGrid