import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { getpayment, getpodetails, getpr } from "../../Api/ApiQuery";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
const PurchaseRequestList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const appointmentListTable = {
    columns: [
      {
        headerName: "PurchaseRequestid",
        field: "purchaseRequestid",
        name: "purchaseRequestid",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        headerName: "PurchaseRequestDate",
        field: "purchaseRequestDate",
        name: "purchaseRequestDate",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "RequiredDate",
        field: "requiredDate",
        name: "requiredDate",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "LegalName",
        field: "legalName",
        name: "legalName",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "Totalamount",
        field: "totalamount",
        name: "totalamount",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "CreatedBy",
        field: "username",
        name: "username",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "CreatedAt",
        field: "createdAt",
        name: "createdAt",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },


     
    
    ],
    rows: data.sort((a,b)=>b.id-a.id).map((val, index) => ({
      
      id: val.id,
      purchaseRequestid: val.purchaseRequestid,
      purchaseRequestDate: val.purchaseRequestDate,
      requiredDate: val.requiredDate,
      legalName: val.vendorName.legalName,
      totalamount:val.totalamount,
      username:val.user.username,
      createdAt:val.createdAt

     
    })),
  };
  const handleNewEmployee = () => {
    
    navigate("/Purchase/purchaserequestcreate");
  };


 const updaterow =(e)=>{
    console.log(e,"event")
    navigate(`/Purchase/pr-update/${e.id}`)
 }

  const fetchpaymentlist = ()=>{
 

    const response = request(BASEURL, getpr)
    .then((res) => {
      console.log(res.allPurchaseRequest,"polist");

      setData(res.allPurchaseRequest)
      // setList(res?.getParentLedger);
      
    })
    .catch((err) => {
      console.log(err);
    });



  }
  useEffect(
    ()=>{
    fetchpaymentlist()},[]
  )
  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Purchase Request List
                </MaterialTypography>
              </Stack>
              <MaterialButton
                variant="gradient"
                color="dark"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleNewEmployee()}
              >
                Add PurchaseRequest
              </MaterialButton>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <MaterialDataGrid
            rows={appointmentListTable.rows}
            columns={appointmentListTable.columns}
            onRowClick={(e)=>updaterow(e)}
          />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default PurchaseRequestList;
