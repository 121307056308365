import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useMaterialUIController, setLayout } from "../../context";
import MaterialBox from "../../components/MaterialBox";
import DashboardFooter from "./components/Footer";
import DashboardNavbar from "./components/Navbar";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MaterialBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 1,
        position: "relative",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <DashboardNavbar />

      <div style={{ minHeight: "74vh" }}>{children}</div>
      <MaterialBox sx={{ mt: 2 }}>
        <DashboardFooter />
      </MaterialBox>
    </MaterialBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
