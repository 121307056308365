import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { setMiniSidenav, useMaterialUIController } from "./context";
import { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./assest/theme";
import Sidenav from "./layoutcontainers/DashboardLayout/components/Sidebar";
import routes from "./routes";
import logo from "../src/assest/images/logo.png";
import AddEmployee from "./pages/employeemanagement/components/AddEmployee";
import Signin from "./pages/authentication/signin";
import LodingCirlce from "./Loading/Loading";
import { useLoading } from "./context/LoadingContext";

// "devextreme": "^24.1.7",
// "devextreme-react": "^24.1.7",

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const token = localStorage.getItem("token");
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };
  const { circleLoading, setCirleLoading } = useLoading();

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    if (pathname == "/sign-in") {
      localStorage.clear();
      sessionStorage.clear();
    }
  }, [pathname]);
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={token ? route.route : "/sign-in"}
            element={token ? route.component : <Signin />}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    // <AddEmployee/>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={logo}
            brandName="Keelis"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      {circleLoading && (
        <div style={{ position: "fixed", inset: 0, zIndex: "1000000000000" }}>
          <LodingCirlce pending={circleLoading} />
        </div>
      )}
      <Routes>
        {getRoutes(routes)}
        <Route
          path="*"
          element={<Navigate to={token ? "/dashboard" : "/sign-in"} />}
        />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
