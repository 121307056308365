import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import "./papercard.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
const PaperCard = ({ children, title }) => {
  return (
    <div className="paper-card-box">
      <div className="flex center papercard-header">
        <div className="flex center">
          <p className="paper-title">{title}</p>
          <HelpIcon sx={{ color: "gray" }} />
        </div>
        <div className="flex center">
          <AddCircleIcon sx={{ color: "green",cursor:'pointer'}} />
          <p className="addnew-paper">New</p>
        </div>
      </div>

      <div style={{ padding: "10px 20px", margin: "14px 0px" }}>{children}</div>

      <div className="paper-footer">
        <div style={{borderRight:"solid 1px grey",paddingRight:"150px"}}>
          <p className="current-text">CURRENT</p>
          <div>
            <p className="blur-text">hwbusfjbnc</p>
            <p className="blur-text">.hbjsbcs</p>
          </div>
        </div>
        <div >
          <div>
          <p className="over-view">OVERDUE</p>
          <div>
          <p className="blur-text">hwbusfjbnc</p>
          <p className="blur-text">.hbjsbcs</p>
          </div>
            </div>
          
        </div>
      </div>
    </div>
  );
};

export default PaperCard;
