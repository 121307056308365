// const BASEURL = 'http://127.0.0.1:8000/graphql/'
// const BASEURL = "http://192.168.0.101:8000/graphql/";

// const BASEURL = "https://accounts.keelis.in/graphql/";
// const BASEURL = "http://192.168.0.193:8000/graphql/"
// const BASEURL = 'http://10.10.1.100:8000/graphql/'
// const BASEURL = 'http://192.168.0.136:8000/graphql/'

 
const BASEURL = 'https://accountsbk.keelis.in/graphql/'
// const BASEURL = 'http://192.168.0.177:8000/graphql/'// essaki local
// const BASEURL = 'http://94.136.187.221:8016/graphql/' //crm live
//  const BASEURL = "https://accounts.keelis.in/graphql/";//accounts Testing
// const BASEURL = "http://94.136.187.221:8004/graphql/";//accounts live

export default BASEURL;
