// import { Snackbar, Alert } from "@mui/material";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import ErrorIcon from "@mui/icons-material/Error";
// import React from "react";

// const NotificationSnackbar = ({ open, handleClose, message, type }) => {
//   return (
//     <Snackbar
//       open={open}
//       autoHideDuration={3000} // Automatically hides after 3 seconds
//       onClose={handleClose}
//       anchorOrigin={{ vertical: "top", horizontal: "center" }}
//     >
//       <Alert
//         onClose={handleClose}
//         severity={type} // 'success' or 'error'
//         variant="filled"
//         iconMapping={{
//           success: <CheckCircleIcon style={{ fontSize: "1.3rem" }} />,
//           error: <ErrorIcon style={{ fontSize: "1.3rem" }} />,
//         }}
//         style={{
//           backgroundColor: type === "success" ? "#4caf50" : "#f44336",
//           color: "#fff",
//           fontSize: "0.8rem",
//           fontWeight: "bold",
//           cursor:"pointer"
//         }}
//       >
//         {message}
//       </Alert>
//     </Snackbar>
//   );
// };

// export default NotificationSnackbar;

import { Snackbar, Alert, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const NotificationSnackbar = ({ open, handleClose, message, type }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} // Automatically hides after 3 seconds
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        severity={type} // 'success' or 'error'
        variant="filled"
        iconMapping={{
          success: <CheckCircleIcon sx={{ fontSize: "1.2rem" }} />,
          error: <ErrorIcon sx={{ fontSize: "1.2rem" }} />,
        }}
        sx={{
          backgroundColor: type === "success" ? "#4caf50" : "#f44336",
          color: "#fff",
          fontSize: "0.8rem",
          // fontWeight: "bold",
        }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            sx={{ padding: 0.5 }} // Reduced padding
          >
            <CloseIcon sx={{ fontSize: "1rem" }} /> {/* Smaller close icon */}
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationSnackbar;


