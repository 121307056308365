import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import "../../assest/style/style.css";
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Icon,
  Select,
  Stack,
} from "@mui/material";

import MaterialTypography from "../../components/MaterialTypography";
import MaterialButton from "../../components/MaterialButton";
import MaterialBox from "../../components/MaterialBox";
import MaterialInput from "../../components/MaterialInput";
import { BsFillPersonVcardFill } from "react-icons/bs";
import MaterialSelect from "../../components/MasterSelect";
import Grid from "@mui/material/Grid2";
import { IconBase } from "react-icons/lib";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from "@mui/icons-material/Percent";
import BASEURL from "../../pages/Utils/Baseurl";
import request from "graphql-request";
import {
  allSalesOrder,
  invoicedetails,
  salesorder,
  viewInvoice,
} from "../../Api/ApiQuery";
import { createaccounts, createinvoice } from "../../Api/apiMutations";
import { useLocation, useNavigate } from "react-router-dom";
import { DataArrayRounded } from "@mui/icons-material";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
function AddInvoice() {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location?.id);
  const edit = location?.state?.type;
  const id = location?.state?.id;

  const [disabled, setDisabled] = useState(false);
  const [productData, setProductData] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [address, setAddress] = useState([]);
  const [terms, setTerms] = useState("");
  const [addition, setAddition] = useState("");
  const [additionStatus, setAdditionStatus] = useState("unchecked");
  const [billingaddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
  });
  const [shippingaddress, setShippingAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
  });
  const [invoice, setInVoice] = useState({
    orderId: "",
    customer: "",
    person: "",
    date: "yyyy-mm-dd",
    duedate: "yyyy-mm-dd",
    productData: [],
    otherChareges: [],
    amount: "",
    totalTaxAmount: "",
    discountAmount: "",
    disPercentage: "",
    totalAmount: "",
    otherCharges: { description: "", amount: "" },

    orderIdStatus: "unchecked",
    customerStatus: "unchecked",
    personStatus: "unchecked",
    invoiceStatus: "unchecked",
    duedateStatus: "unchecked",
  });
  const [salesOrder, setSalesOrder] = useState([]);

  const [togglePage, setTogglePage] = useState({
    view: false,
    edit: false,
  });
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    showCancel: true,
    showConfirm: true,
    customSection: null,
    onConfirm: () => {},
    onCancel: () => {},
  });
  const handleProductDataChange = (event, selectEvent) => {
    console.log(event.target.value, selectEvent);

    let temp = { ...invoice };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "orderId") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          temp[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          temp[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "error" : "error";
        }
      }
      temp[selectEvent.name] = event;
    } else if (event.target.name == "duedate") {
      const alphaNLenghthRegex = new RegExp(
        "^[a-zA-Z0-9 !@#$%^&*()_+{}|:\"<>?`\\[\\]\\\\;,./'-=~]{10,150}$"
      );
      if (alphaNLenghthRegex.test(event.target.value?.trim())) {
        temp[event.target.name + "Status"] = "success";
      } else {
        temp[event.target.name + "Status"] =
          event.target.value === "" ? "unchecked" : "error";
      }
      temp[event.target.name] = event?.target?.value;
    }
    setInVoice(temp);
  };

  const getList = () => {
    const response = request(BASEURL, allSalesOrder)
      .then((res) => {
        const temp = [];
        const data = res?.allSalesOrder?.map((item) => {
          if (item?.invoiceSet?.length == 0) {
            temp?.push(item);
          }
        });
        setSalesOrder(temp);
        // setList(res?.getParentLedger);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, []);
  // console.log(address, billingaddress, shippingaddress);

  const getProduct = (event,date) => {
    if (event?.value) {
      const response = request(BASEURL, salesorder, {
        id: event?.value,
      })
        .then((res) => {
          console.log(res);
          const data = res?.getSalesOrderId;
          const address = [];
          address.push({
            line1: data?.customer?.address1Line1,
            line2: data?.customer?.address1Line2,
            city: data?.customer?.city1,
            country: data?.customer?.country1,
            state: data?.customer?.state1,
            zipcode: data?.customer?.zipCode1,
          });
          if (data?.customer?.address2Line1) {
            address.push({
              line1: data?.customer?.address2Line1,
              line2: data?.customer?.address2Line2,
              city: data?.customer?.city2,
              country: data?.customer?.country2,
              state: data?.customer?.state2,
              zipcode: data?.customer?.zipCode2,
            });
          }

          setAddress(address);
          const shipping = address.find(
            (value) => value?.line1 == data?.shippingAddress
          );
          // console.log(shipping, data?.shippingAddress);

          const billing = address.find(
            (value) => value?.line1 == data?.billingAddress
          );
          if (shipping) {
            setShippingAddress(shipping);
          }
          if (billing) {
            setBillingAddress(billing);
          }
          setInVoice({
            ...invoice,
            duedate: data?.quotation?.dueDate,
            // duedateStatus: "success",
            orderId: event,
            orderIdStatus: "success",
            customer: data?.customer?.legalName,
            person: data?.contactPerson,
            date: date ,
            otherChareges: data?.otherchargeSet,
            productData: data?.salesorderproductsSet,
            totalAmount: data?.totalAmount,
            totalTaxAmount: data?.totalTaxAmount,
            amount: data?.subTotal,
            discountAmount: data?.discountSet?.[0]?.amount,
            disPercentage: data?.discountSet?.[0]?.discountPercentage,
          });
          // setSalesOrder(res?.allSalesOrder);
          // setList(res?.getParentLedger);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChangeHandelor = (e) => {
    const alphaNLenghthRegex = new RegExp("^([A-Za-z0-9 ]{2,250})$");
    if (alphaNLenghthRegex.test(e.target.value.trim())) {
      setAdditionStatus("success");
    } else {
      setAdditionStatus("error");
    }
    setTerms(e.target.value);
  };
  const disabledHandel = () => {
    if (
      invoice?.orderIdStatus == "success" &&
      // invoice?.duedateStatus == "success" &&
      additionStatus == "success"
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };
  useEffect(() => {
    disabledHandel();
  }, [invoice, additionStatus]);
  console.log(disabled, "disabled");
  const userdetails = localStorage.getItem("user");
  const userid = JSON.parse(userdetails);
  const manual = [
    {
      user: userid?.id,
      ledger: "2",
      debit: invoice?.totalAmount,
      voucherType: "Invoice",
      credit: "0",
      voucherDate: today,
    },
    {
      user: userid?.id,
      ledger: "3",
      debit: "0",
      voucherType: "Invoice",
      credit: invoice?.totalAmount,
      voucherDate: today,
    },
  ];
  const AddInvoice = () => {
    setConfirmationDialog({
      open: true,
      title: "Confirmation",
      message: "Are you sure do you want to submit your details.",
      showCancel: true,
      showConfirm: true,
      onConfirm: () => {
        const userdetails = localStorage.getItem("user");
        const userid = JSON.parse(userdetails);

        const values = {
          userId: userid?.id,
          soId: invoice?.orderId?.value,
          customer: invoice?.customer,
          contactPerson: invoice?.person,
          invoiceDate: invoice?.date,
          dueDate: invoice?.duedate,
          amount: parseFloat("0.00"),
          totalTaxAmount: parseFloat(invoice?.totalTaxAmount || 0),
          discountPercentage: parseFloat(invoice?.disPercentage || 0),
          roundOffAmount: parseFloat("0.00"),
          totalAmount: parseFloat(invoice?.totalAmount || 0),
          termsAndConditions: terms,
          additionalInformation: addition,
          billingAddress: billingaddress?.line1,
          billingCountry: billingaddress?.country,
          billingState: billingaddress?.state,
          billingCity: billingaddress?.city,
          billingZipline: billingaddress?.zipcode,
          shippingAddress: shippingaddress?.line1,
          shippingCountry: shippingaddress?.country,
          shippingState: shippingaddress?.state,
          shippingCity: shippingaddress?.city,
          shippingZipline: shippingaddress?.zipcode,
          subtotal: parseFloat(invoice?.amount || 0),
          products: invoice?.productData.map((value) => ({
            productId: value?.id,
          })),
          otherCharges: invoice?.otherChareges.map((value) => ({
            description: value?.description,
            amount: parseFloat(value?.amount || 0),
          })),
          discount: {
            discountPercentage: parseFloat(invoice?.disPercentage || 0),
            amount: parseFloat(invoice?.discountAmount || 0),
          },
        };

        request(BASEURL, createinvoice, { input: values })
          .then((res) => {
            console.log(res);
            navigate("/Invoice");
            setConfirmationDialog({
              open: true,
              title: "Success message",
              message: "Successfully Ledger Added",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
          })
          .catch((err) => {
            setConfirmationDialog({
              open: true,
              title: "Error message",
              message:
                "We are unable to fetch the required data at this time. Please try again later. If the problem persists, please contact support.",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
            console.log(err);
          });

        const response = request(BASEURL, createaccounts, {
          input: manual,
        })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel: () => {
        setConfirmationDialog({
          ...confirmationDialog,
          open: false,
        });
      },
    });
  };

  const viewedit = (id) => {
    const res = request(BASEURL, viewInvoice, {
      id: id,
    })
      .then((res) => {
        // console.log(res);
        const data = res?.invoice;

        // setAddition(data?.)
        setTerms(data?.termsAndConditions);

        // setInVoice(value);
        getProduct(
          {
            value: data?.so?.id,
            label: data?.so?.salesOrderId,
          },
          data?.invoiceDate
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (id) {
      viewedit(id);
    }
  }, []);
  console.log(invoice, "invoice");

  return (
    <DashboardLayout>
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          showCancel={confirmationDialog.showCancel}
          showConfirm={confirmationDialog.showConfirm}
          customSection={confirmationDialog.customSection}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={confirmationDialog.onCancel}
          done={confirmationDialog.done}
          back={confirmationDialog.back}
        />
      )}
      <Card>
        <CardHeader
          title={
            <>
              {/* {togglePage.view === true || togglePage.edit === true ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Stack direction="column" spacing={0}>
                    <MaterialTypography variant="h5" fontWeight="medium">
                      Invoice Add
                    </MaterialTypography>
                  </Stack>
                </Stack>
              ) : ( */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column" spacing={0}>
                  <MaterialTypography variant="h5" fontWeight="medium">
                    Add Invoice
                  </MaterialTypography>
                </Stack>
              </Stack>
              {/* )} */}
            </>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Invoice Details
            </MaterialTypography>
          </Stack>
          <MaterialBox>
            <Grid container spacing={2}>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Sales Order ID <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  isDisabled={edit ? true : false}
                  name="orderId"
                  defaultValue={{ label: "Please select" }}
                  customStyles={{ optionPadding: 20 }}
                  onChange={(e) => getProduct(e,today)}
                  options={
                    Array.isArray(salesOrder) &&
                    salesOrder.map((value) => {
                      return {
                        value: value.id,
                        label: value.salesOrderId,
                      };
                    })
                  }
                  value={invoice.orderId}
                  error={invoice.orderIdStatus === "error" ? true : false}
                  success={invoice.orderIdStatus === "success" ? true : false}
                  //   isDisabled={!disbledStatus}
                  // isClearable
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Customer
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="customer"
                  value={invoice?.customer}
                  defaultValue={{ label: "Please select" }}
                  customStyles={{ optionPadding: 20 }}
                  placeholder="Enter Customer"
                  // onChange={handleProductDataChange} //selectedValue, event
                  //   options={customerOption.map((value) => {
                  //     return {
                  //       value: value.id,
                  //       label: value.legalName,
                  //     };
                  //   })}
                  error={invoice.invoiceStatus === "error" ? true : false}
                  success={invoice.invoiceStatus === "success" ? true : false}
                  disabled
                  isClearable
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Contact Person
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  placeholder="Enter Contact Person "
                  name="billingAddress"
                  value={invoice?.person}
                  defaultValue={{ label: "Please select" }}
                  customStyles={{ optionPadding: 20 }}
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Invoice Date
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="shippingAddress"
                  type="date"
                  value={invoice?.date}
                  defaultValue={{ label: "Please select" }}
                  customStyles={{ optionPadding: 20 }}
                  disabled
                  isClearable
                />
                {/* {quationFieldData.shippingAddressStatus === "error" && (
                  <FormControl error>
                    <FormHelperText>
                      First Name should be maximum 20 characters
                    </FormHelperText>
                  </FormControl>
                )} */}
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Due Date
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  disabled
                  name="duedate"
                  type="date"
                  value={invoice?.duedate}
                  defaultValue={{ label: "Please select" }}
                  onChange={handleProductDataChange}
                  customStyles={{ optionPadding: 20 }}
                  error={invoice.duedateStatus === "error" ? true : false}
                  success={invoice.duedateStatus === "success" ? true : false}
                  isClearable
                />
              </Grid>
            </Grid>
          </MaterialBox>
          <Divider />
          <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Product Details
            </MaterialTypography>
          </Stack>
          <Box>
            {invoice?.productData?.map((v, i) => {
              return (
                <div key={i} className="mainproduct-box">
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Product Name
                    </MaterialTypography>
                  </MaterialBox>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "95%" }}>
                      <MaterialInput
                        name="productName"
                        value={v?.productName?.productName}
                        defaultValue={{ label: "Please select" }}
                        customStyles={{ optionPadding: 20 }}
                        onChange={(e) =>
                          handleProductDataChange(e, i, "productName")
                        } //selectedValue, event
                        options={productData?.map((value) => {
                          return {
                            value: value?.id,
                            label: value?.productName,
                          };
                        })}
                        disabled
                        isClearable
                      />{" "}
                    </div>
                  </div>

                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="productdeatils-insidebox">
                      <Stack
                        direction="row"
                        spacing={1}
                        className="inside-head"
                        style={{ backgroundColor: "#3F51B5d4" }}
                      >
                        {/* <BsFillPersonVcardFill style={{ fontSize: 18 }} /> */}
                        <MaterialTypography
                          component="label"
                          variant="body2"
                          fontWeight="normal"
                        >
                          Description
                        </MaterialTypography>
                      </Stack>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              HSN
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="HSN"
                            placeholder="Hsn"
                            value={v.HSNNumber}
                            // onChange={handleleadandProspectData}
                            error={v.HSNStatus === "error" ? true : false}
                            success={v.HSNStatus === "success" ? true : false}
                            disabled={true}
                          />
                        </Grid>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              SKU
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="SKU"
                            placeholder="Sku"
                            value={v.SKU}
                            // onChange={handleleadandProspectData}
                            error={v.SKUStatus === "error" ? true : false}
                            success={v.SKUStatus === "success" ? true : false}
                            disabled={true}
                          />
                        </Grid>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Comments
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="comments"
                            placeholder="Enter comments"
                            value={v.comments}
                            disabled={true}
                          />
                          {v.commentsStatus === "error" && (
                            <FormControl error>
                              <FormHelperText>
                                maximum 50 characters
                              </FormHelperText>
                            </FormControl>
                          )}
                        </Grid>
                      </div>
                    </div>
                    <div className="productdeatils-insidebox">
                      <Stack
                        direction="row"
                        spacing={1}
                        className="inside-head"
                        style={{ backgroundColor: "#3F51B5d4" }}
                      >
                        {/* <BsFillPersonVcardFill style={{ fontSize: 18 }} /> */}
                        <MaterialTypography
                          component="label"
                          variant="body2"
                          fontWeight="normal"
                        >
                          Qty / Unit / Rate
                        </MaterialTypography>
                      </Stack>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Quantity
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="quantity"
                            placeholder="Enter quantity"
                            value={v.quantity}
                            onChange={(e) =>
                              handleProductDataChange(e, i, "quantity")
                            } //selectedValue, event
                            error={v.quantityStatus === "error" ? true : false}
                            success={
                              v.quantityStatus === "success" ? true : false
                            }
                            disabled
                          />
                        </Grid>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Rate
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="rate"
                            placeholder="Enter Rate"
                            value={v.productrate}
                            // onChange={handleleadandProspectData}
                            error={v.rateStatus === "error" ? true : false}
                            success={v.rateStatus === "success" ? true : false}
                            disabled={true}
                          />
                        </Grid>
                      </div>
                    </div>
                    <div className="productdeatils-insidebox">
                      <Stack
                        direction="row"
                        spacing={1}
                        className="inside-head"
                        style={{ backgroundColor: "#3F51B5d4" }}
                      >
                        {/* <BsFillPersonVcardFill style={{ fontSize: 18 }} /> */}
                        <MaterialTypography
                          component="label"
                          variant="body2"
                          fontWeight="normal"
                        >
                          Tax
                        </MaterialTypography>
                      </Stack>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Tax %
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="tax"
                            value={v?.tax}
                            defaultValue={{ label: "Please Select Tax" }}
                            customStyles={{ optionPadding: 20 }}
                            disabled
                            isClearable
                            icon={{
                              direction: "right",
                              component: (
                                <Icon color="action" fontSize="small">
                                  <PercentIcon />
                                </Icon>
                              ),
                            }}
                          />{" "}
                        </Grid>
                      </div>
                    </div>
                    <div className="productdeatils-insidebox">
                      <Stack
                        direction="row"
                        spacing={1}
                        className="inside-head"
                        style={{ backgroundColor: "#3F51B5d4" }}
                      >
                        {/* <BsFillPersonVcardFill style={{ fontSize: 18 }} /> */}
                        <MaterialTypography
                          component="label"
                          variant="body2"
                          fontWeight="normal"
                        >
                          Amount
                        </MaterialTypography>
                      </Stack>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Amount
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="amount"
                            placeholder="Amount"
                            value={v.totalproductamount}
                            // onChange={handleleadandProspectData}

                            disabled={true}
                            icon={{
                              direction: "right",
                              component: (
                                <Icon color="action" fontSize="small">
                                  <CurrencyRupeeIcon />
                                </Icon>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid size={{ xs: 5, sm: 3 }}>
                          <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Tax Amount
                            </MaterialTypography>
                          </MaterialBox>
                          <MaterialInput
                            name="taxAmount"
                            placeholder="Tax Amount "
                            value={v.taxAmount}
                            // onChange={handleleadandProspectData}
                            error={v.taxAmountStatus === "error" ? true : false}
                            success={
                              v.taxAmountStatus === "success" ? true : false
                            }
                            disabled={true}
                            icon={{
                              direction: "right",
                              component: (
                                <Icon color="action" fontSize="small">
                                  <CurrencyRupeeIcon />
                                </Icon>
                              ),
                            }}
                          />
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <Divider />
            <Stack direction="row" spacing={1}>
              <BsFillPersonVcardFill style={{ fontSize: 20 }} />
              <MaterialTypography
                component="label"
                variant="body2"
                fontWeight="bold"
              >
                Amount Details
              </MaterialTypography>
            </Stack>
            <MaterialBox>
              <Grid container spacing={2}>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      SubTotal
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    placeholder="Enter Amount"
                    value={invoice?.amount}
                    defaultValue={{ label: "Please select" }}
                    customStyles={{ optionPadding: 20 }}
                    disabled
                    isClearable
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Total Tax Amount
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="customer"
                    placeholder="Enter Total Tax Amount"
                    value={invoice?.totalTaxAmount}
                    defaultValue={{ label: "Please select" }}
                    customStyles={{ optionPadding: 20 }}
                    disabled
                    isClearable
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Discount Amount
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="billingAddress"
                    placeholder="Enter   Discount Amount"
                    value={invoice?.discountAmount}
                    defaultValue={{ label: "Please select" }}
                    customStyles={{ optionPadding: 20 }}
                    disabled
                    //   isDisabled={togglePage?.view === true ? true : false}
                    isClearable
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Discount Percent
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="billingAddress"
                    placeholder="Enter Discount Percent"
                    value={invoice?.disPercentage}
                    defaultValue={{ label: "Please select" }}
                    customStyles={{ optionPadding: 20 }}
                    disabled
                    //   isDisabled={togglePage?.view === true ? true : false}
                    isClearable
                  />
                </Grid>

                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Total Amount
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="shippingAddress"
                    placeholder="Enter Total Amount"
                    value={invoice?.totalAmount}
                    customStyles={{ optionPadding: 20 }}
                    disabled
                    isClearable
                  />
                  {/* {quationFieldData.shippingAddressStatus === "error" && (
                  <FormControl error>
                    <FormHelperText>
                      First Name should be maximum 20 characters
                    </FormHelperText>
                  </FormControl>
                )} */}
                </Grid>
              </Grid>
            </MaterialBox>
            <Divider />
            <Stack direction="row" spacing={1}>
              <BsFillPersonVcardFill style={{ fontSize: 20 }} />
              <MaterialTypography
                component="label"
                variant="body2"
                fontWeight="bold"
              >
                Billing Address
              </MaterialTypography>
            </Stack>
            <MaterialBox>
              <Grid container spacing={2}>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address Line 1
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={billingaddress?.line1}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter   Address Line 1"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address Line 2
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={billingaddress?.line2}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter   Address Line 2"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Country
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={billingaddress?.country}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter country"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      State
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={billingaddress?.state}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter state"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      City
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={billingaddress?.city}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter city"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Zip Code
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={billingaddress?.zipcode}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter zipcode"
                    isClearable
                    disabled
                  />
                </Grid>
              </Grid>
            </MaterialBox>
            <Divider />
            <Stack direction="row" spacing={1}>
              <BsFillPersonVcardFill style={{ fontSize: 20 }} />
              <MaterialTypography
                component="label"
                variant="body2"
                fontWeight="bold"
              >
                Shipping Address
              </MaterialTypography>
            </Stack>
            <MaterialBox>
              <Grid container spacing={2}>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address Line 1
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={shippingaddress?.line1}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter Address Line 1"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Address Line 2
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={shippingaddress?.line2}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter Address Line 1"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Country
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={shippingaddress?.country}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter Country"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      State
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={shippingaddress?.state}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter State"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      City
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={shippingaddress?.city}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter City"
                    isClearable
                    disabled
                  />
                </Grid>
                <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Zip Code
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="opportunityID"
                    value={shippingaddress?.zipcode}
                    customStyles={{ optionPadding: 20 }}
                    placeholder="Enter Zipcode"
                    isClearable
                    disabled
                  />
                </Grid>
              </Grid>
            </MaterialBox>
            <Divider />
            <Stack direction="row" spacing={1}>
              <BsFillPersonVcardFill style={{ fontSize: 20 }} />
              <MaterialTypography
                component="label"
                variant="body2"
                fontWeight="bold"
              >
                Other Charges
              </MaterialTypography>
            </Stack>
            {invoice?.otherChareges?.map((value, ind) => {
              return (
                <MaterialBox>
                  <Grid container spacing={2}>
                    <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                      <MaterialBox lineHeight={0} display="inline-block">
                        <MaterialTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Description
                        </MaterialTypography>
                      </MaterialBox>
                      <MaterialInput
                        name="opportunityID"
                        value={value?.description}
                        customStyles={{ optionPadding: 20 }}
                        placeholder="Enter Description"
                        isClearable
                        disabled
                      />
                    </Grid>
                    <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                      <MaterialBox lineHeight={0} display="inline-block">
                        <MaterialTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Amount
                        </MaterialTypography>
                      </MaterialBox>
                      <MaterialInput
                        name="opportunityID"
                        value={value?.amount}
                        customStyles={{ optionPadding: 20 }}
                        placeholder="Enter Amount"
                        isClearable
                        disabled
                      />
                    </Grid>
                  </Grid>
                </MaterialBox>
              );
            })}
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  paddingRight: "20px",
                }}
              >
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Terms and condition <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <div style={{ width: "100%" }}>
                  <textarea
                    style={{
                      minHeight: "120px",
                      maxHeight: "120px",
                      maxWidth: "100%",
                      minWidth: "100%",
                      border: "1px solid ",

                      borderRadius: "5px",
                      padding: "15px",
                      marginTop: "10px",
                      borderColor: `${
                        additionStatus == "success"
                          ? "#32f738 "
                          : additionStatus == "error"
                          ? "red"
                          : "gray"
                      }`,
                      outline: 0,
                    }}
                    onChange={(e) => onChangeHandelor(e)}
                    placeholder="Terms and condition"
                    value={terms}
                    disabled={edit ? true : false}
                  />
                  {additionStatus === "error" && (
                    <FormControl error>
                      <FormHelperText>
                        Terms and Condition should be minumum 3 characters and
                        maximum 250 characters
                      </FormHelperText>
                    </FormControl>
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  paddingLeft: "20px",
                }}
              >
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Additional Informations{" "}
                  </MaterialTypography>
                </MaterialBox>
                <textarea
                  disabled={edit ? true : false}
                  style={{
                    minHeight: "120px",
                    maxHeight: "120px",
                    maxWidth: "100%",
                    minWidth: "100%",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    padding: "15px",
                    marginTop: "10px",
                  }}
                  placeholder="Additional Informations"
                  onChange={(e) => setAddition(e.target.value)}
                />
              </div>
            </div>
          </Box>
        </CardContent>
        <MaterialBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          {togglePage.edit === true ? (
            <>
              <MaterialButton
                variant="gradient"
                color="dark"
                // onClick={handleDiscardButton}
              >
                Discard
              </MaterialButton>
              <MaterialButton
                sx={{ m: 2 }}
                variant="gradient"
                color="dark"
                // disabled={!checkAllFields()}
                // onClick={handleEditQuationData}
              >
                Submit
              </MaterialButton>
            </>
          ) : togglePage.view === false && togglePage.edit === false ? (
            <MaterialButton
              sx={{ m: 2 }}
              variant="gradient"
              color="dark"
              disabled={disabled == true ? true : false}
              onClick={AddInvoice}
            >
              Submit
            </MaterialButton>
          ) : null}
        </MaterialBox>
      </Card>
    </DashboardLayout>
  );
}

export default AddInvoice;
