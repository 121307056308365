import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { getpayment } from "../../Api/ApiQuery";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
const PaymentList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const appointmentListTable = {
    columns: [
      {
        headerName: " Payment Voucher Id",
        field: "paymentvoucherId",
        name: "paymentvoucherId",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        headerName: "Posting Date",
        field: "postingDate",
        name: "Time",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Against",
        field: "against",
        name: "against",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Payment Amount",
        field: "paymentAmount",
        name: "paymentAmount",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "Created By",
        field: "createdby",

        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "Created At",
        field: "createdAt",

        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

     
    
    ],
    rows: data.map((val, index) => ({
      
      id: val.id,
      paymentvoucherId: val.paymentvoucherId,
      against: val.against,
      paymentAmount: val.paymentAmount,
      postingDate: val.postingDate,
      createdAt:val.createdAt.slice(0,10),
      createdby:val.user.username,
     
    })),
  };
  const handleNewEmployee = () => {
    navigate("/Payment/Add-Payment");
  };

  const fetchpaymentlist = ()=>{
 

    const response = request(BASEURL, getpayment)
    .then((res) => {
      console.log(res.allPaymentVoucher);

      setData(res.allPaymentVoucher)
      // setList(res?.getParentLedger);
      
    })
    .catch((err) => {
      console.log(err);
    });



  }
  useEffect(
    ()=>{
    fetchpaymentlist()},[]
  )
  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Payment List
                </MaterialTypography>
              </Stack>
              <MaterialButton
                variant="gradient"
                color="dark"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleNewEmployee()}
              >
                Add Payment
              </MaterialButton>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <MaterialDataGrid
            rows={appointmentListTable.rows}
            columns={appointmentListTable.columns}
            // onRowClick={""}
          />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default PaymentList;
