import React from "react";
import "./Loading.css";

const LodingCirlce = ({ pending = false }) => {
  return (
    <div
      className="loadin-main-box"
      style={pending ? { display: "flex" } : { display: "none" }}
    >
      {pending && (
        <>
          {/* <div class="loader">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
</div> */}

          <div class="loader"></div>
        </>
      )}
    </div>
  );
};

export default LodingCirlce;
