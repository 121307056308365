import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import { allInvoice } from "../../Api/ApiQuery";
import "../../assest/style/style.css";
import { FaFolderClosed, FaFolderOpen } from "react-icons/fa6";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
const InvoiceList = () => {
  const handleNewEmployeeEdit = (id) => {
    navigate("/Invoice/Add-Invoice",{state:{type:"edit",id:id}});
  };
  const [pageState, setPageState] = useState({
    loading: false,
    error: false,
    message: "",
    state: "init",
  });
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const appointmentListTable = {
    columns: [
      {
        headerName: "Invoice Id",
        field: "invoiceId",
        // name: "Date",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        headerName: "Customer Name",
        field: "customer",
        // name: "Time",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Invoice Date",
        field: "invoiceDate",
        name: "Name",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Total Amount",
        field: "totalAmount",
        name: "Phone Number",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Actions",
        field: "action",
        name: "Status",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
    ],
    rows: data.map((val, index) => ({
      id: val.id,
      invoiceId: val.invoiceId,
      customer: val.customer,
      invoiceDate: val.invoiceDate,
      totalAmount: val.totalAmount,
      action: (
        <RemoveRedEyeIcon
          sx={{
            transform: "scale(1.5)",
            marginRight: "35px",
            cursor: "pointer",
            color: "gray",
                // marginLeft:'50px'
          }}
          onClick={()=>handleNewEmployeeEdit(val?.id)}
        />
      ),
      // createdBy: val.user.username,
    })),
  };
  const handleNewEmployee = () => {
    navigate("/Invoice/Add-Invoice");
  };


  const getList = () => {
    const response = request(BASEURL, allInvoice)
      .then((res) => {
        console.log(res);
        // setList(res?.getParentLedger);
        setData(res?.allInvoice);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Invoice List
                </MaterialTypography>
              </Stack>
              <MaterialButton
                variant="gradient"
                color="dark"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleNewEmployee()}
              >
                Add Invoice
              </MaterialButton>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          {/* <MaterialDataGrid
            rows={appointmentListTable.rows}
            columns={appointmentListTable.columns}
          /> */}

          <MaterialDataGrid
            // customHeight={550}
            rows={appointmentListTable.rows}
            columns={appointmentListTable.columns}
            checkboxSelection={false}
            isSelectable={true}
            // onRowClick={onStaffListClick}
            loading={pageState.loading}
            localeText={{
              noRowsLabel: (
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <MaterialTypography
                    variant="body2"
                    fontWeight="medium"
                    color="text"
                  >
                    No staff found
                  </MaterialTypography>
                  <MaterialTypography variant="caption" color="text">
                    You have not yet add any staff details. Click on the "Add
                    staff" button to start adding staff details.
                  </MaterialTypography>
                </Stack>
              ),
            }}
          />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default InvoiceList;
