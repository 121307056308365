import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { getpayment, getpodetails } from "../../Api/ApiQuery";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
const PurchaseList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const appointmentListTable = {
    columns: [

      {
        headerName: "Purchase-Order-id",
        field: "purchaseOrderid",
        name: "purchaseOrderid",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Due Date",
        field: "dueDate",
        name: "dueDate",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        headerName: "Order Date",
        field: "orderDate",
        name: "orderDate",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
     
      {
        headerName: "Total Amount",
        field: "totalamount",
        name: "totalamount",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "Legal Name",
        field: "legalName",
        name: "legalName",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "Created By",
        field: "createdby",

        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      {
        headerName: "Created At",
        field: "createdAt",

        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      


     
    
    ],
    rows: data.sort((a,b)=>b.id-a.id).map((val, index) => ({
      
      id: val.id,
      purchaseOrderid: val.purchaseOrderid,
      orderDate: val.orderDate,
      dueDate: val.dueDate,
      totalamount: val.totalamount,
      legalName:val.vendor.legalName,
      createdAt:val.createdAt.slice(0,10),
      createdby:val.user.username,
      

     
    })),
  };
  const handleNewEmployee = () => {
    
    navigate("/Purchase/purchasecreate");
  };


 const updaterow =(e)=>{
    console.log(e,"event")
    navigate(`/Purchase/po-update/${e.id}`)
 }

  const fetchpaymentlist = ()=>{
 

    const response = request(BASEURL, getpodetails)
    .then((res) => {
      console.log(res.allPurchase,"polist");

      setData(res.allPurchase)
      // setList(res?.getParentLedger);
      
    })
    .catch((err) => {
      console.log(err);
    });



  }
  useEffect(
    ()=>{
    fetchpaymentlist()},[]
  )
  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Purchase Order List
                </MaterialTypography>
              </Stack>
              <MaterialButton
                variant="gradient"
                color="dark"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleNewEmployee()}
              >
                Add PurchaseOrder
              </MaterialButton>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <MaterialDataGrid
            rows={appointmentListTable.rows}
            columns={appointmentListTable.columns}
            onRowClick={(e)=>updaterow(e)}
          />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default PurchaseList;
