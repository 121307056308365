// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

import MaterialBox from "../../../../components/MaterialBox";
import MaterialTypography from "../../../../components/MaterialTypography";
import typography from "../../../../assest/theme/base/typography";

function DashboardFooter({ company }) {
  const { size } = typography;

  return (
    <MaterialBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MaterialBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="dark"
        fontSize={size.sm}
        px={1.5}
      >
        Developed by
          <MaterialTypography variant="button" fontWeight="medium">
            &nbsp;{'Keelis'}&nbsp;
          </MaterialTypography>
      </MaterialBox>
    </MaterialBox>
  );
}

// Typechecking props for the DashboardFooter
DashboardFooter.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default DashboardFooter;
