import { AddCircleOutline, Dashboard, Delete } from "@mui/icons-material"
import DashboardLayout from "../../layoutcontainers/DashboardLayout"
import { Formik, Form, Field, FieldArray, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup'
import { Box, Card, CardContent, CardHeader, Divider, FormControl, FormHelperText, Grid2, IconButton, InputLabel, MenuItem, Stack, Typography } from '@mui/material'
import MaterialTypography from "../../components/MaterialTypography";
import { BsFillPersonVcardFill } from "react-icons/bs";
import MaterialBox from "../../components/MaterialBox";
import MaterialInput from "../../components/MaterialInput";
import MaterialSelect from "../../components/MasterSelect";
import { Button } from "devextreme-react";
import MaterialButton from "../../components/MaterialButton";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import { getbillingaddress, poupdate, proddetails, products, vendors } from "../../Api/ApiQuery";
import { useEffect, useState } from "react";
import { createpo, updatepo } from "../../Api/apiMutations";
import { useNavigate, useParams } from "react-router-dom";
import { debounce } from 'lodash';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
 
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import NotificationSnackbar from "./snackbar";

const PurchaseOrder = ()=>{


    // const vendors = [
    //     { value: 1, label: "tcs" },
    //     { value: 2, label: "zoho" },
    //     { value: 3, label: "accenture" },
    //   ];

    const[purchaseorderproductSet,setProducts] =useState([])
    const [otherchargeSet,setCharges]  = useState([])
    const[po,setPo] =useState({})
    const[updatestatus,setupdate] = useState(false)
    const [formValues,setFormValues] = useState(null)
    const[open,setOpen] = useState(false)
    const [notificationType, setNotificationType] = useState("");
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");

    const navigate = useNavigate()
    const [touchedFields, setTouchedFields] = useState({});
    var isTouched = false;
  const handleBlur = (field) => {
    setTouchedFields((prev) => ({ ...prev, [field]: true }));
    isTouched = touchedFields[field];
  };
  const handleDialogClose = () => setOpen(false);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };
  

          const {id} = useParams()
          useEffect(() => {
            if (id) {
              request(BASEURL, poupdate, { id })
                .then((res) => {
                  console.log(res, "updateres");
                  setPo(res.purchase);
                  setProducts(res.purchase.purchaseorderproductSet || []);
                  setCharges(res.purchase.otherchargeSet || []);
                  setupdate(true)
                
                })
                .catch((err) => {
                  console.log(err, "error occurred while fetching the update details");
                  
                });
            } 
          }, [id]);

    const currentDate = new Date();

    // Extract day, month, and year
    const day = String(currentDate.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const year = currentDate.getFullYear();
    
    // Combine in dd-mm-yyyy format
    const formattedDate = `${year}-${month}-${day}`;
    
    

 
      
    const [Allvendors,setallvendors]  = useState([])
    const [Allproducts,setallproducts]  = useState([])
    const [Address,setAddress] =useState({})
    
    const userdetails = localStorage.getItem("user");
    const userid = JSON.parse(userdetails);
    const decodedString = atob(userid?.id);
    const originalNumber = decodedString.split(":")[1];

         const create =  {
          input:{
            
            userId: Number(originalNumber),
            vendorId: '',
            orderDate: formattedDate,
            billingAddress: '',
            dueDate:formattedDate,
            shippingAddress: '',
            paymentTerms: '',
            termsAndConditions: '',
            subTotal: '',
            totalAmount: '',
            totalTaxAmount: '',
            amountAndTax:'',
            discountAmount: 0,
            discountPercentage: 0,
            products: [
              {
                productNameId: '',
                sku: '',
                hsnNumber: '',
                comments: '',
                quantity: '',
                rate: '',
                tax: '',
                productRate: 100,
                totalProductAmount: '',
                taxAmount: '',
                productDiscount: 5,
                productDiscountPercentage: 5
              }
            ],
            otherCharges: [
              {
                description: '',
                amount: ''
              }
            ]


          }



  }  
 
      console.log(po?.vendor?.id,"logg")
       const update ={
        
          input: {  
            id: id,
            vendorId: Number(po?.vendor?.id) || '',
            orderDate: po?.orderDate || '',
            billingAddress: po?.billingAddress || '',
            dueDate: po?.dueDate || '',
            shippingAddress: po?.shippingAddress || '',
            paymentTerms: po?.paymentTerms || '',
            termsAndConditions: po?.termsAndConditions || '',
            subTotal: Number(po?.subtotal) || 0,
            totalAmount: Number(po?.totalamount) || 0,
            totalTaxAmount: Number(po?.totalTaxAmount) || 0,
            amountAndTax: Number(po?.amountandTax) || 0,
            discountAmount: Number(po?.discountAmount) || 0,
            discountPercentage: Number(po?.discountpercentage) || 0,
            products: purchaseorderproductSet?.map((product) => ({
              // userId: Number(originalNumber),
              id: Number(product?.id) || 0,
              productNameId: Number(product?.productName?.id) || 0,
              sku: product?.SKU || 0,
              hsnNumber: product?.HSNNumber || 0,
              comments: product?.comments || '',
              quantity: Number(product?.quantity) || 0,
              rate: Number(product?.rate) || 0,
              tax: Number(product?.tax) || 0,
              productRate: Number(product?.productRate) || 0,
              totalProductAmount: Number(product?.totalproductamount) || 0,
              taxAmount: Number(product?.taxAmount) || 0,
              productDiscount: 0,
              productDiscountPercentage: 0,
            })) || [],
            otherCharges: otherchargeSet?.map((charges) => ({
              description: charges?.description || '',
              amount: Number(charges?.amount) || 0,
              chargeId: Number(charges?.id) || 0
            })) || [],
          },
        };





useEffect(()=>{
 console.log(purchaseorderproductSet,"  ")
 console.log(otherchargeSet,"charges")
 console.log(po,"po")

},[purchaseorderproductSet,otherchargeSet,po])

   console.log(purchaseorderproductSet,"valset")
         
  const initialvalues = purchaseorderproductSet.length>0?update:create

  console.log(purchaseorderproductSet,"pset")

     console.log(create,'create')
    console.log(initialvalues,"invalu")
   
    if (!purchaseorderproductSet || purchaseorderproductSet.length === 0) {
      console.log("dummy")
      console.log(create,"insideif")
      initialvalues.input = { 
        ...initialvalues.input, 
        products: create.input.products, 
        otherCharges: create.input.otherCharges ,
        orderDate:create.input.orderDate,
        dueDate: create.input.dueDate
      };
    }
    console.log(initialvalues, "invalu");
    
    
   
   

  const validationSchema = Yup.object({
    input: Yup.object({
    
      orderDate: Yup.date().required('Order Date required'),
      vendorId: Yup.number().required('Vendor ID is required'),
      billingAddress: Yup.string().required('Billing address is required'),
      shippingAddress: Yup.string().required('Shipping address is required'),
      paymentTerms: Yup.string().max(250,'Payment terms are required'),
      termsAndConditions: Yup.string().required('Terms and conditions are required'),
      subTotal: Yup.number().nullable().transform(value => (value === '' ? null : value)).required('Subtotal is required'),
      totalAmount: Yup.number().nullable().transform(value => (value === '' ? null : value)).required('Total amount is required'),
      totalTaxAmount: Yup.number().nullable().transform(value => (value === '' ? null : value)).required('Total tax amount is required'),
      amountAndTax: Yup.number().nullable().transform(value => (value === '' ? null : value)).required('Amount and tax are required'),
      discountAmount: Yup.number().nullable().transform(value => (value === '' ? null : value)).required('Discount amount is required'),
      discountPercentage: Yup.number(),
      products: Yup.array().of(
        Yup.object({
          productNameId: Yup.number().required('Product Name ID is required'),
          sku: Yup.string(),
          hsnNumber: Yup.string(),
          comments:  Yup.string().max(50, 'Comments cannot exceed 50 characters'),
          quantity: Yup.number()
          .required('Quantity is Mandatory') // Mandatory field
          .test(
            'maxDigits',
            'Quantity must be a number with a maximum of 4 digits',
            value => !value || value.toString().length <= 4 // Custom test for max 4 digits
          )
          .typeError('Quantity must be a valid number'), // Ensures it's a number,
          rate: Yup.number().required('Rate is required'),
          tax: Yup.number().required('Tax is required'),
          
          totalProductAmount: Yup.number().required('Total product amount is required'),
          taxAmount: Yup.number().required('Tax amount is required'),

        })
      ),
      otherCharges: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required('Description is required'),
          amount: Yup.number().required('Amount is required'),
        })
      ),
    })
  });
  

  const handleSubmit = async (values) => {
    console.log("inside handlesubmit",values)
    setFormValues(values)
    setOpen(true)

  //   if(id){
        
      
  //     try {
  //       const response = request(
  //      BASEURL,updatepo,{
  //        input: values.input
        
  
  //      }
  
  
  //       ).then((res)=>{
  //         console.log("form submited",res)
  //       }).catch((err)=>{
  //         console.log("form not submited",err)
  //       });
     
  //       alert('Form submitted successfully!');
       
  //     } catch (error) {
  //       console.error('Error submitting form:', error);
  //       alert('Failed to submit form. Please try again later.');
  //     } 



  //   }
  //   else{

  //   try {
  //     const response = request(
  //    BASEURL,createpo,{
  //      input: values.input
      

  //    }


  //     ).then((res)=>{
  //       console.log("form submited",res)
  //     }).catch((err)=>{
  //       console.log("form not submited",err)
  //     });
   
  //     alert('Form submitted successfully!');
     
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //     alert('Failed to submit form. Please try again later.');
  //   } 
   
  // }
  
  
  };  

  const handleConfirmSubmit = async()=>{
     
    setOpen(false)
    if(!formValues) return;

    const values = formValues;

    if(id){
        
      
      try {
        const response = request(
       BASEURL,updatepo,{
         input: values.input
        
  
       }
  
  
        ).then((res)=>{
          console.log("form submited",res)
          setNotificationMessage("Updated Successfully!");
          setNotificationType("success");
          setNotificationOpen(true);
          setTimeout(() => {
            navigate("/purchase-order");
          }, 3000);
        }).catch((err)=>{
          console.log("form not submited",err)
          setNotificationMessage("not Updated");
     setNotificationType("error");
     setNotificationOpen(true);
     setTimeout(() => {
      navigate("/purchase-order");
    }, 3000);
        });
     
        
       
      } catch (error) {
        console.error('Error submitting form:', error);
       
      } 



    }
    else{

    try {
      const response = request(
     BASEURL,createpo,{
       input: values.input
      

     }


      ).then((res)=>{
        console.log("form submited",res)

        setNotificationMessage("Created Successfully!");
        setNotificationType("success");
        setNotificationOpen(true);
     
        // Redirect after success
        setTimeout(() => {
          navigate("/purchase-order");
        }, 3000);
      }).catch((err)=>{
        console.log("form not submited",err)
        setNotificationMessage("not created");
        setNotificationType("error");
        setNotificationOpen(true);
        setTimeout(() => {
         navigate("/purchase-order");
       }, 3000);
      });
   
      
     
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form. Please try again later.');
    } 
   
  }

  }


  // get all the vendors 


  const getallvendors = ()=>{


    const response = request(BASEURL,vendors).then((res)=>{
      console.log(res.allVendor,'allvendors');   
       
        setallvendors(res.allVendor)
      
    })   .catch((err) => {
      console.log(err);
    });
  }

  const getallproducts = ()=>{
    const response = request(BASEURL,products).then((res)=>{
      console.log(res.allProduct,'allproducts');   
       setallproducts(res.allProduct)
        
      
    })   .catch((err) => {
      console.log(err);
    });

  }

  useState(()=>{
    getallvendors()
    getallproducts()
  },[])
  const Against = [
    {
      value: "Payment 30 days after invoice date",
      label: "Payment 30 days after invoice date",
    },
    {
      value: "Payment 10 days after invoice date",
      label: "Payment 10 days after invoice date",
    },
    {
      value: "Payment 7 days after invoice date",
      label: "Payment 7 days after invoice date",
    },

    {
      value: "Payment 45 days after invoice date",
      label: "Payment 45 days after invoice date",
    },


    {
      value: "Payment 45 days after invoice date",
      label: "Payment 45 days after invoice date",
    },

    {
      value: "Payment in advance",
      label: "Payment in advance",
    },


  ];


  const tax = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 5,
      label: "5%",
    },
    {
      value: 12,
      label: "12%",
    },

    {
      value: 18,
      label: "18%",
    },

    {
      value: 28,
      label: "28%",
    },
  ];
  console.log("against",Against)

  const fetchproddetails = (id) => {
    return request(BASEURL,proddetails,{
      id:id
    }).then((response)=>{

      console.log("Product details response:", response.getProductdetail);
      return response.getProductdetail;

    })

   .catch((err)=>{

    console.log(err)
   })
  };
  
  // Usage

  
  const emptyValidationSchema = Yup.object().shape({});

 return(

   
    <DashboardLayout >
    <Formik
    initialValues= {initialvalues}
    enableReinitialize
    validationSchema={validationSchema}
   
    onSubmit={handleSubmit}
    
    >
    {
        ({values,getFieldProps,touched,errors,setFieldValue,handleSubmit,setFieldTouched})=>{

           console.log(values)
           console.log(errors,"error")

        //    const calculatedAmountAndTax = 
        //    Number(values.input.subTotal || 0) + Number(values.input.totalTaxAmount || 0);
         
        //  // Update the `amountAndTax` field only if the value has changed
        //  if (values.input.amountAndTax !== calculatedAmountAndTax) {
        //    setFieldValue('input.amountAndTax', calculatedAmountAndTax);
        //  }
           
        //  const updatedtotal =  values.input.products.reduce((acc,product)=>{
        //     return acc+Number(product.totalProductAmount)

        //   },0)

        //   const updatedtax = values.input.products.reduce((acc,product)=>{
        //     return acc+Number(product.taxAmount)
        //   },0)


        //   const updatedtotalamt = values.input.otherCharges.reduce((acc,charge)=>{

        //     return acc+Number(charge.amount)
        //   },
          
        //   0)
        //   const curamt$tax = Number(values.input.amountAndTax)



        //   const discountamt =(updatedtotalamt+curamt$tax)*(Number(values.input.discountPercentage)?Number(values.input.discountPercentage)/100:0)
        //   console.log(discountamt,"discamt")
        //   const finalamount = updatedtotalamt+curamt$tax+discountamt

        //   if(finalamount!=Number(values.input.totalAmount)){

        //     setFieldValue('input.totalAmount',finalamount)
        //     setFieldValue('input.discountAmount',discountamt)
        //   }
        //   if(values.input.subTotal!=updatedtotal){
        //     setFieldValue(`input.subTotal`,updatedtotal)
        //   }
        //   if(values.input.totalTaxAmount!=updatedtax){
        //     setFieldValue(`input.totalTaxAmount`,updatedtax)
        //   }

           const getaddress =(id)=>{
             
           const response = request(BASEURL,getbillingaddress,{
             id:id
           }).then((res)=>{
            console.log(res.getVendorDetail
              ,"addreddres")
              setAddress(res.getVendorDetail)

           }).catch((err)=>{
            console.log(err)
           })

           }

           const calculateTotals = (index,numericValue) => {
                
            // Calculate total charges including the updated value
            const updatedOtherCharges = [...values.input.otherCharges];
            updatedOtherCharges[index].amount = numericValue;
        
            const totalCharges = updatedOtherCharges.reduce((acc, charge) => {
              return acc + (Number(charge.amount) || 0);
            }, 0);
        
            // Calculate total tax
            const totalTax = values.input.products.reduce((acc, product) => {
              return acc + (Number(product.taxAmount) || 0);
            }, 0);
        
            // Calculate subtotal
            const subTotal = values.input.products.reduce((acc, product) => {
              return acc + (Number(product.totalProductAmount) || 0);
            }, 0);
        
            // Update total amount
            const totalAmount = subTotal + totalTax + totalCharges;
            setFieldValue('input.totalAmount', totalAmount);
          }; // Debounce by 300ms


            return(
          <Form >
              <Card>
                  <CardHeader title={

                    (
                        <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        >
                        <Stack direction="column" spacing={0}>
                            <MaterialTypography variant="h5" fontWeight="medium">
                                Purchase Order
                            </MaterialTypography>
                        </Stack>
                      </Stack>
                  

                    )
                  }
                   
                  
                  
                  
                  
                  />

                  <CardContent>

                  <Stack direction="row" spacing={1} >
                <BsFillPersonVcardFill style={{ fontSize: 20 }} />
                <MaterialTypography
                    component="label"
                    variant="body2"
                    fontWeight="bold">
                    Basic Details
                </MaterialTypography>
            </Stack>

            <MaterialBox>
                 <Grid2 container spacing={1} size={{xs: 12, sm: 12, lg: 12 }}>
                    <Grid2 size={{xs:12,sm:6,lg:6}}>
                    <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Order Date <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                        <MaterialInput name={"input.orderDate"}  {...getFieldProps("input.orderDate")}  type={"date"}
                        
                  
                        />
           
                        
                    </Grid2>

                    <Grid2 size={{xs:12,sm:6,lg:6}}>
                    <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Vendor <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                     
        {
          console.log('Selected Vendor:',
  Allvendors.find((option) => option.id === values.input.vendorId))
        }
                        {/* <MaterialSelect
                   name="input.vendorId"
  value={
    Allvendors.find((option) => option.id === values.input.vendorId) || null
  } 
  options={Allvendors.map((option) => ({
    value: option.id,
    label: option.legalName,
  }))} 
  onChange={(selectedOption) => {
    setFieldValue('input.vendorId', selectedOption ? selectedOption.value : ''); 
  }}
  isClearable
/> */}
<MaterialSelect
  name="input.vendorId"
  value={
    Allvendors.length > 0
      ? {
          value: Number(values.input.vendorId),
          label: Allvendors.find((option) => Number(option.id) === Number(values.input.vendorId))?.legalName || '',
        }
      : null
  } // Match the options format exactly
  options={Allvendors.map((option) => ({
    value: option.id,
    label: option.legalName,
  }))} // Correctly format options
  onChange={(selectedOption) => {
    if(selectedOption){
    setFieldValue('input.vendorId', selectedOption ? Number(selectedOption.value) : ''); // Update Formik value
      getaddress(selectedOption.value)
    }
    else{
      console.log("unselect")
      setFieldValue('input.vendorId', ''); // Update Formik value
    }
    // setFieldTouched('input.vendorId', true);
  }}
 

  onBlur={() => setFieldTouched('input.vendorId', true)} // Mark as touched
  isClearable
  error={touched.input?.vendorId && errors.input?.vendorId ? true : false}
  success={touched.input?.vendorId && !errors.input?.vendorId ? true : false}


/>


                        
                        





                        </Grid2>


                        <Stack direction="row" spacing={1} >
                <BsFillPersonVcardFill style={{ fontSize: 20 }} />
                <MaterialTypography
                    component="label"
                    variant="body2"
                    fontWeight="bold">
                    Product Details
                </MaterialTypography>
            </Stack>      

            <FieldArray name="input.products">
              
                 { ({push,remove})=>(

               <>
             <Box
  sx={{
    display: "flex", // Use flexbox
    justifyContent: "flex-end", // Align items to the right
    width: "100%", // Ensure it spans the full width of its parent
  }}
>
  <IconButton
    onClick={() =>
      push({
        productNameId: "",
        
        sku: "",
        hsnNumber: "",
        comments: "",
        quantity: "",
        rate: 0,
        tax: 0,
        productRate: 0,
        totalProductAmount: 0,
        taxAmount: 0,
        productDiscount: 0,
        productDiscountPercentage: 0,
      })
    }
  >
    <AddCircleOutline />
  </IconButton>
</Box>

             {values.input.products.map((_,index)=>(

             <Grid2 key={index} container spacing={1}  size={{xs:12,sm:12,lg:12}} sx={{border:"1px solid #ddd",padding:5}}>
                 

                 <Grid2 size={index > 0 ? { xs: 12, sm: 6, lg: 6 } : { xs: 12, sm: 6, lg: 12}}>
                 <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Product Name <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>



{
          console.log('Selected product:',
            Allproducts.find(
              (option) => option.id === values.input.products[index].productNameId
            )  )      }
          

<MaterialSelect
  name={`input.products.${index}.productNameId`}
  value={
    Allproducts.length > 0 && values.input.products[index].productNameId
      ? {
          value: Number(values?.input?.products[index]?.productNameId),
          label: Allproducts.find(
            (option) => Number(option.id) === Number(values.input.products[index].productNameId)
          )?.productName || '',
        }
      : null
  } 
  // options={Allproducts.map((option) => ({
  //   value: Number(option.id),
  //   label: option.productName,
  // }))} 

  options = {
    Allproducts.filter((option)=>(!values.input.products.some(prod=>prod.productNameId==option.id))).map((option)=>({

          value: Number(option.id),
         label: option.productName,

    }))
  }
  onChange={(selectedOption) => {

      

    const selectedProduct = Allproducts.find(
      (product) => Number(product.id) === selectedOption?.value
    );


    setFieldValue(
      `input.products.${index}.productNameId`,
      selectedOption ? selectedOption.value : null
    ); 

     
    if (selectedProduct) {
      console.log("selectedopt ",selectedOption.value)
     
       const proddetail = fetchproddetails(selectedOption.value).then((details)=>{
         if(details){
          console.log(details,"detail3")

          const { HSNNumber, SKU, productCost } = details; // Destructure to extract values
          console.log("HSN Number:", HSNNumber);
          console.log("SKU:", SKU);
          console.log("Product Cost:", productCost);
          setFieldValue(`input.products.${index}.hsnNumber`,HSNNumber)
          setFieldValue(`input.products.${index}.rate`,productCost)
          setFieldValue(`input.products.${index}.sku`,SKU)
          console.log("index:", index);
          console.log("quantconsole",values.input.products[index].quantity)
          console.log("ratelog",productCost)
          if(values.input.products[index].quantity){
            console.log("quantamt",values.input.products[index].quantity)
          console.log("ratecons",productCost);
          

            const prodamt = Number(values.input.products[index].quantity)* Number(productCost)
            console.log("prodamt",prodamt);
            setFieldValue(`input.products.${index}.totalProductAmount`,prodamt)

            

          }
          else{

            setFieldValue(`input.products.${index}.totalProductAmount`, '')
          }
       
         }
         else{
          console.log("no data found");
          
         }


       }).catch((err)=>{

        console.log(err)
       })

       console.log(proddetail ,"prodet")

      
       


    } else {
      // Clear related fields if no product is selected
      setFieldValue(`input.products.${index}.hsnNumber`, '');
      setFieldValue(`input.products.${index}.sku`, '');
      setFieldValue(`input.products.${index}.rate`, '');
      setFieldValue(`input.products.${index}.totalProductAmount`, '');
      setFieldValue(`input.products.${index}.taxAmount`, '');
      
    }
     

  }}

    // onBlur={()=>handleBlur(`input.products.${index}.productNameId`)}


  
  
  isClearable


  onBlur={() => setFieldTouched(`input.products.${index}.productNameId`, true)} // Mark as touched
    error={
      touched.input?.products?.[index]?.productNameId &&
      errors.input?.products?.[index]?.productNameId
        ? true
        : false
    }
    success={
      touched.input?.products?.[index]?.productNameId &&
      !errors.input?.products?.[index]?.productNameId
        ? true
        : false
    }
  
/>

{touched.input?.products?.[index]?.productNameId &&
    errors.input?.products?.[index]?.productNameId && (
      <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
        {errors.input.products[index].productNameId}
      </div>
    )}


</Grid2>
                
                
                 {index>0?  
                 
                 <Grid2
                  size={{xs:6,sm:6,lg:6}}
                  sx={{
                    display: "flex", // Use flexbox
                    justifyContent: "flex-end", // Align items to the right
                    width: "100%", // Ensure it spans the full width of its parent
                  }}
                 >

                 
               <IconButton onClick={() => remove(index)}>
                 <Delete />

               </IconButton>
               </Grid2>
            
               :""
           
            
            }



               <Grid2 size={{xs:6,sm:6,lg:3}} sx={{border:"1px solid #ddd",padding:2,minHeight:"50vh"}}>
                 <div style={{width:"98%",backgroundColor:"skyblue", height:"15%",
                   display:"flex",justifyContent:"center",alignItems:"center"}}>
                   Description
                 </div>
                 
                 <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                HSN 
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.products.${index}.hsnNumber`} 
         value = {values.input.products[index].hsnNumber}
         disabled
          type={"text"}/>



<MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                SKU 
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.products.${index}.sku`} 
        value = {values.input.products[index].sku}
        disabled
         type={"text"}/>



<MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Comments 
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.products.${index}.comments`} 
        {...getFieldProps(`input.products.${index}.comments`)}  type={"text"}/>
                  
               </Grid2>

               <Grid2 size={{xs:6,sm:6,lg:3}} sx={{border:"1px solid #ddd",padding:2,minHeight:"50vh"}}>
               <div style={{width:"98%",backgroundColor:"skyblue", height:"15%",
                   display:"flex",justifyContent:"center",alignItems:"center"}}>
                   Quantity/Unit/Rate
                 </div>
                 
                             
                 <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Quantity <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>
              {console.log(isTouched,"istouch")
                
              }

{console.log(touchedFields,"touchedFields")
                
              }
              
       <MaterialInput name={`input.products.${index}.quantity`} 
        {...getFieldProps(`input.products.${index}.quantity`)}  


        
        
        type={"number"}
        
        onChange={

          (e)=>{

            if (!/^\d*$/.test(e.target.value)) {
              console.log('Non-numeric input ignored:', e.target.value);
              return;
            }
            setFieldValue(`input.products.${index}.quantity`,Number(e.target.value))
            if(values.input.products[index].rate){
              console.log("rateval",values.input.products[index].rate)
              console.log("qualval",e.target.value)

              console.log("pramt",Number(values.input.products[index].rate)*Number(e.target.value))
              const prodamt = Number(values.input.products[index].rate)*Number(e.target.value)
              setFieldValue(`input.products.${index}.totalProductAmount`,prodamt)
              

              const newSubTotal = values.input.products.reduce((acc, product, i) => {
                const productAmount =
                  i === index ? prodamt : Number(product.totalProductAmount) || 0;
                return acc + productAmount;
              }, 0);

              const totalTax = values.input.products.reduce((acc, product, i) => {
                const currentTaxAmount = Number(product.taxAmount) || 0;
                return acc + currentTaxAmount;
              }, 0);

              console.log("calculated subtotal:", newSubTotal);
              setFieldValue(`input.subTotal`, newSubTotal);
              setFieldValue('input.amountAndTax',newSubTotal+totalTax)
              setFieldValue('input.totalAmount',newSubTotal+totalTax) 
            }
            else{
              console.log("rate is not available");
              
            }

          }
        }
          

        onBlur={() => setFieldTouched(`input.products.${index}.quantity`, true)} // Mark as touched
        error={
          touched.input?.products?.[index]?.quantity && errors.input?.products?.[index]?.quantity
            ? true
            : false
        }
        success={
          touched.input?.products?.[index]?.quantity && !errors.input?.products?.[index]?.quantity
            ? true
            : false
        }
         


     
    
        />

{touched.input?.products?.[index]?.quantity && errors.input?.products?.[index]?.quantity && (
    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
      {errors.input.products[index].quantity}
    </div>
  )}




                           
<MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Rate <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.products.${index}.rate`} 
       value = {values.input.products[index].rate}
         disabled
          type={"text"}
           
          onBlur={() => setFieldTouched(`input.products.${index}.rate`, true)} // Mark as touched
          error={
            touched.input?.products?.[index]?.rate && errors.input?.products?.[index]?.rate
              ? true
              : false
          }
          success={
            touched.input?.products?.[index]?.rate && !errors.input?.products?.[index]?.rate
              ? true
              : false
          }
          
          />

               </Grid2>

               <Grid2 size={{xs:6,sm:6,lg:3}} sx={{border:"1px solid #ddd",padding:2,minHeight:"50vh"}}>
               <div style={{width:"98%",backgroundColor:"skyblue", height:"15%",
                   display:"flex",justifyContent:"center",alignItems:"center"}}>
                   Tax
                 </div>

                 <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Tax % <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                        {/* <MaterialSelect
                   name={`input.products.${index}.tax`}
  value={
    tax.find((option) => option.value === values.input.products[index].tax) || null
  } // This ensures the value is correctly formatted as { value, label }
  options={tax.map((option) => ({
    value: option.value,
    label: option.label,
  }))} // Passes options correctly formatted
  onChange={(selectedOption) => {
    setFieldValue(`input.products.${index}.tax`, selectedOption ? selectedOption.value : ''); // Updates Formik field

    if(values.input.products[index].totalProductAmount){
       const taxpercent = Number(selectedOption.value)
       const totalamt = Number(values.input.products[index].totalProductAmount)

       console.log('tax%',taxpercent)
       console.log("totamt",totalamt)
       const taxamt = (totalamt/100)*taxpercent
       console.log('taxamt',taxamt)

      setFieldValue(`input.products.${index}.taxAmount`,taxamt)
    }
  }}
  isClearable
/> */}


{/* <MaterialSelect
  name={`input.products.${index}.tax`}
  value={
    tax.find((option) => option.value === values.input.products[index].tax) || null
  } // This ensures the value is correctly formatted as { value, label }
  options={tax.map((option) => ({
    value: option.value,
    label: option.label,
  }))} // Passes options correctly formatted
  onChange={(selectedOption) => {
    if (selectedOption) {
      // Set the tax value in Formik
      setFieldValue(`input.products.${index}.tax`, selectedOption.value);

      // Perform calculations only when `totalProductAmount` exists
      if (values.input.products[index].totalProductAmount) {
        const taxpercent = Number(selectedOption.value);
        const totalamt = Number(values.input.products[index].totalProductAmount);

        console.log('tax%', taxpercent);
        console.log("totamt", totalamt);

        const taxamt = (totalamt / 100) * taxpercent;
        console.log('taxamt', taxamt);

        // Update taxAmount in Formik
        setFieldValue(`input.products.${index}.taxAmount`, taxamt);


        const tottax = values.input.products.reduce((acc,product,i)=>{
       

        const currval =   i==index ?taxamt:Number(values.input.products[i].taxAmount)||0
        return acc+currval

        },0)
        setFieldValue('input.totalTaxAmount',tottax)
      }
    } else {
      // Handle clearing the field
      setFieldValue(`input.products.${index}.tax`, '');
      setFieldValue(`input.products.${index}.taxAmount`, 0); // Clear tax amount if needed


     const tottax =  values.input.products.reduce((acc,product)=>{
        const curval = Number(product.taxAmount)||0
        return curval+acc

      },0)
      setFieldValue('input.totalTaxAmount',tottax)
      
    }
  }}
  isClearable
/> */}

<MaterialSelect
  name={`input.products.${index}.tax`}
  value={
    tax.find((option) => option.value === values.input.products[index].tax) || null
  } // Correctly format the selected value
  options={tax.map((option) => ({
    value: option.value,
    label: option.label,
  }))} // Format the options correctly
  onChange={(selectedOption) => {
    if (selectedOption) {
      // Set the tax value
      setFieldValue(`input.products.${index}.tax`, selectedOption.value);

      if (values.input.products[index].totalProductAmount) {
        const taxpercent = Number(selectedOption.value);
        const totalamt = Number(values.input.products[index].totalProductAmount);

        const taxamt = (totalamt / 100) * taxpercent;

        // Update taxAmount for this product
        setFieldValue(`input.products.${index}.taxAmount`, taxamt);

        
        
        // Recalculate totalTaxAmount
        const totalTax = values.input.products.reduce((acc, product, i) => {
          const currentTaxAmount = i === index ? taxamt : Number(product.taxAmount) || 0;
          return acc + currentTaxAmount;
        }, 0);

        const newSubTotal = values.input.products.reduce((acc, product, i) => {
          const productAmount =
            Number(product.totalProductAmount) || 0;
          return acc + productAmount;
        }, 0);

        setFieldValue('input.amountAndTax',newSubTotal+totalTax)
        setFieldValue('input.totalAmount',newSubTotal+totalTax)
        setFieldValue('input.totalTaxAmount', totalTax);
     


      }
    } else {
      // Clear the tax value and tax amount
      setFieldValue(`input.products.${index}.tax`, '');
      setFieldValue(`input.products.${index}.taxAmount`, 0); // Set to 0 instead of ''

      // Recalculate totalTaxAmount
      const totalTax = values.input.products.reduce((acc, product,i) => {
        const currentTaxAmount = i===index?0:Number(product.taxAmount) || 0;
        return acc + currentTaxAmount;
      }, 0);

      const newSubTotal = values.input.products.reduce((acc, product, i) => {
        const productAmount =
          Number(product.totalProductAmount) || 0;
        return acc + productAmount;
      }, 0);

      console.log("tottaxinelse",totalTax)
      setFieldValue('input.totalTaxAmount', totalTax);
      setFieldValue('input.amountAndTax',newSubTotal+totalTax)
      setFieldValue('input.totalAmount',newSubTotal+totalTax)
    }
  }}
  isClearable
    // onBlur = {()=>handleBlur(`input.products.${index}.tax`)}

    onBlur={() => setFieldTouched(`input.products.${index}.tax`, true)} 
    error={touched.input?.products?.[index]?.tax && errors.input?.products?.[index]?.tax ? true : false} // Display error if touched and validation fails
    success={touched.input?.products?.[index]?.tax && !errors.input?.products?.[index]?.tax ? true : false}

/>

{touched.input?.products?.[index]?.tax && errors.input?.products?.[index]?.tax && (
    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
      {errors.input.products[index].tax}
    </div>
  )}




               </Grid2>

               <Grid2 size={{xs:6,sm:6,lg:3}} sx={{border:"1px solid #ddd",padding:2,minHeight:"50vh"}}>
               <div style={{width:"98%",backgroundColor:"skyblue", height:"15%",
                   display:"flex",justifyContent:"center",alignItems:"center"}}>
                   Amount
                 </div>


                 <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                ProductAmount <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.products.${index}.totalProductAmount`} 
       value = {values.input.products[index].totalProductAmount}
  
         disabled
          type={"text"}/>

<MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                taxAmount <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.products.${index}.taxAmount`} 
       value = {values.input.products[index].taxAmount}
         disabled
          type={"text"}/>
               </Grid2>

             
             </Grid2>
           

             )
            )
             }

             
             </>
                 )

                 }
             </FieldArray>  

             <Grid2 size={{xs:12,sm:12,lg:12}} container spacing={1}>
              
             <Grid2 size={{xs:12,sm:6,lg:4}}>
              
                                              
<MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Subtotal<span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                        <MaterialInput 
  name={`input.subTotal`} 
  value={values.input.subTotal}
  readOnly // Allows event handling while preventing user editing
 
  type="text"
/>

              </Grid2>

              <Grid2 size={{xs:12,sm:6,lg:4}}>
              
              <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Total Tax<span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.totalTaxAmount`} 
       value = {values.input.totalTaxAmount}
         disabled
          type={"text"}/>
              


              </Grid2>


              <Grid2 size={{xs:12,sm:6,lg:4}}>
              
              <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Amount$Tax<span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.amountAndTax`} 
       value = {values.input.amountAndTax}
         disabled
          type={"text"}/>

              </Grid2>


             </Grid2>

             {/* //otherCharges */}

             <FieldArray name="input.otherCharges">
                  {
                    ({push,remove})=>(
                     <>
                      <Box
                      sx={{
                        display: "flex", // Use flexbox
                        justifyContent: "space-between", // Align items to the right
                        width: "100%", // Ensure it spans the full width of its parent
                      }}
                    >
                               <Stack direction="row" spacing={1} >
                <BsFillPersonVcardFill style={{ fontSize: 20 }} />
                <MaterialTypography
                    component="label"
                    variant="body2"
                    fontWeight="bold">
                    Other Charges
                </MaterialTypography>
            </Stack>  

                      <IconButton
                        onClick={() =>
                          push({
                            description: '',
                               amount: ''
                          })
                        }
                      >
                        <AddCircleOutline />
                      </IconButton>
                    </Box>
                     
                     { values.input.otherCharges.map((_,index)=>(

                       <Grid2 size={{xs:12,sm:6,lg:6}} sx={{border:"1px solid black",padding:3}} container spacing={1}>
                        <Grid2 size={{xs:12,sm:6,lg:10}}>
                         

                           <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                description <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>
        
       <MaterialInput name={`input.otherCharges.${index}.description`} 
        {...getFieldProps(`input.otherCharges.${index}.description`)}

          type={"text"}
         
          error={
            touched.input?.otherCharges?.[index]?.description &&
            errors.input?.otherCharges?.[index]?.description
              ? true
              : false
          }
          success={
            touched.input?.otherCharges?.[index]?.description &&
            !errors.input?.otherCharges?.[index]?.description
              ? true
              : false
          }
       />

{touched.input?.otherCharges?.[index]?.description &&
    errors.input?.otherCharges?.[index]?.description && (
      <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
        {errors.input.otherCharges[index].description}
      </div>
    )}



        
                        </Grid2>

                        <Grid2 size={{xs:12,sm:2,lg:2}}>
                           
                           { index>0?<IconButton onClick={() => remove(index)}>
                 <Delete />

               </IconButton>:""}
                           </Grid2>


                           <Grid2 size={{xs:12,sm:6,lg:10}}>
                             

                             <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Amount<span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

       {/* <MaterialInput name={`input.otherCharges.${index}.amount`} 
        {...getFieldProps(`input.otherCharges.${index}.amount`)}  type={"number"}
        error={
          touched.input?.otherCharges?.[index]?.amount &&
          errors.input?.otherCharges?.[index]?.amount
            ? true
            : false
        }
        success={
          touched.input?.otherCharges?.[index]?.amount &&
          !errors.input?.otherCharges?.[index]?.amount
            ? true
            : false
        }
        
        
        /> */}

        {/* <MaterialInput
          name={`input.otherCharges.${index}.amount`} 
          value={values.input.otherCharges[index]?.amount || ''}  // Ensure you get the correct value
          type="number"
          onChange={(e) => {
            const inputValue = e.target.value;
        
                console.log(inputValue,"inval2")
              
            if(inputValue){
              
            const numericValue = Number(inputValue);
            console.log(numericValue,"numval")
            setFieldValue(`input.otherCharges.${index}.amount`, numericValue);
        
            // Calculate total charges including the updated value
            const updatedOtherCharges = [...values.input.otherCharges];
            updatedOtherCharges[index].amount = numericValue;
        
            const totalCharges = updatedOtherCharges.reduce((acc, charge) => {
              return acc + (Number(charge.amount) || 0);
            }, 0);
        
            // Calculate total tax
            const totalTax = values.input.products.reduce((acc, product) => {
              return acc + (Number(product.taxAmount) || 0);
            }, 0);
        
            // Calculate subtotal
            const subTotal = values.input.products.reduce((acc, product) => {
              return acc + (Number(product.totalProductAmount) || 0);
            }, 0);
        
            // Update total amount
            const totalAmount = subTotal + totalTax + totalCharges;
            setFieldValue('input.totalAmount', totalAmount);
          }
          }}
          onBlur={() => setFieldTouched(`input.otherCharges.${index}.amount`, true)}  // Set the field as touched on blur
          error={
            touched.input?.otherCharges?.[index]?.amount &&
            errors.input?.otherCharges?.[index]?.amount
              ? true
              : false
          }
          success={
            touched.input?.otherCharges?.[index]?.amount &&
            !errors.input?.otherCharges?.[index]?.amount
              ? true
              : false
          }
        /> */}

<MaterialInput
  name={`input.otherCharges.${index}.amount`}
  value={values.input.otherCharges[index]?.amount || ''}
  onChange={(e) => {
    const numericValue = Number(e.target.value) || 0;
    setFieldValue(`input.otherCharges.${index}.amount`, numericValue);
     // Inline calculation for totals
  const totalCharges = values.input.otherCharges.reduce((acc, charge, i) => {
    return i === index
      ? acc + numericValue
      : acc + (Number(charge.amount) || 0);
  }, 0);

  const totalTax = values.input.products.reduce((acc, product) => {
    return acc + (Number(product.taxAmount) || 0);
  }, 0);

  const subTotal = values.input.products.reduce((acc, product) => {
    return acc + (Number(product.totalProductAmount) || 0);
  }, 0);

  const totalAmount = subTotal + totalTax + totalCharges;
  setFieldValue('input.totalAmount', totalAmount);
  }}
  onBlur={() => setFieldTouched(`input.otherCharges.${index}.amount`, true)}

  error={
    touched.input?.otherCharges?.[index]?.amount &&
    errors.input?.otherCharges?.[index]?.amount
      ? true
      : false
  }
/>


{touched.input?.otherCharges?.[index]?.amount &&
    errors.input?.otherCharges?.[index]?.amount && (
      <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
        {errors.input.otherCharges[index].amount}
      </div>
    )}
                           </Grid2>
                      
                       </Grid2>
                       

                      ))

                    }


                      </>
                    )

                  }

              

             </FieldArray>

             <Grid2 size={{xs:12,sm:6,lg:6}} container spacing={0} sx={{padding:3}}>
                   
             <Grid2 size={{xs:12,sm:12,lg:8}}>
             <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Discount%
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.discountPercentage`} 
        // {...getFieldProps(`input.discountPercentage`)}  
        
        
        type={"number"}
         value={values.input.discountPercentage} 
        onChange ={(e)=>{
          setFieldValue("input.discountPercentage",e.target.value)
          if(e.target.value){
            
              
               const discountpercent = 100-Number(e.target.value)

              const finalamount = Number(values.input.totalAmount) *(discountpercent/100)
               const discountamount = Number(values.input.totalAmount) - finalamount
               console.log(discountamount,"discamt")
              setFieldValue("input.totalAmount",finalamount)
              setFieldValue("input.discountAmount",discountamount)


          }
       

          else{
            console.log("inside else of disount")

            
            // Calculate total charges including the updated value
            const updatedOtherCharges = [...values.input.otherCharges];
          
        
            const totalCharges = updatedOtherCharges.reduce((acc, charge) => {
              return acc + (Number(charge.amount) || 0);
            }, 0);
        
            // Calculate total tax
            const totalTax = values.input.products.reduce((acc, product) => {
              return acc + (Number(product.taxAmount) || 0);
            }, 0);
        
            // Calculate subtotal
            const subTotal = values.input.products.reduce((acc, product) => {
              return acc + (Number(product.totalProductAmount) || 0);
            }, 0);
        
            // Update total amount
            const totalAmount = subTotal + totalTax + totalCharges;
            setFieldValue('input.totalAmount', totalAmount);
        
          }


        }}
        
        
        />
                     
                   </Grid2>

                   <Grid2 size={{xs:12,sm:12,lg:8}}>
                  
                   <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Total Amount<span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

       <MaterialInput name={`input.totalAmount`} 
        value={values.input.totalAmount}   disabled type={"number"}/>

                   </Grid2>



             </Grid2>
               {/* Delivery Details */}
             <Grid2 size={{xs:12,sm:12,lg:12}} container spacing={1}>

             <Grid2 size={{xs:12,sm:12,lg:12}}>
             <Stack direction="row" spacing={1} >
                <BsFillPersonVcardFill style={{ fontSize: 20 }} />
                <MaterialTypography
                    component="label"
                    variant="body2"
                    fontWeight="bold">
                   Delivery Details
                </MaterialTypography>
            </Stack>  
            </Grid2>

            <Grid2 size={{xs:12,sm:12,lg:6}}>
            
            <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Due Date <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                        <MaterialInput name={"input.dueDate"}  {...getFieldProps("input.dueDate")}  type={"date"}
                        
                        error={
                          touched.input?.dueDate && errors.input?.dueDate ? true : false
                        }
                        success={
                          touched.input?.dueDate && !errors.input?.dueDate ? true : false
                        }
                        
                        
                        />

{touched.input?.dueDate && errors.input?.dueDate && (
    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
      {errors.input.dueDate}
    </div>
  )}


            </Grid2>

            <Grid2 size={{xs:12,sm:12,lg:6}}>
               
            <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Billing Address <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                        <MaterialSelect
                   name={`input.billingAddress`}
  value={
    Address?{

      value: values.input.billingAddress,
      label: values.input.billingAddress
    }:null
  } 
  options={
    Object.entries(Address).map(([key, value]) => ({
      value: key,
      label: value,
    }))
  }
   // Passes options correctly formatted
  onChange={(selectedOption) => {
    console.log("seloptval",selectedOption)
    setFieldValue(`input.billingAddress`, selectedOption ? selectedOption.label: ''); // Updates Formik field
  }}

  onBlur={() => setFieldTouched('input.billingAddress', true)}
  isClearable
  error={
    touched.input?.billingAddress && errors.input?.billingAddress
      ? true
      : false
  }
  success={
    touched.input?.billingAddress && !errors.input?.billingAddress
      ? true
      : false
  }


/>

{touched.input?.billingAddress && errors.input?.billingAddress && (
    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
      {errors.input.billingAddress}
    </div>
  )}

            </Grid2>

            <Grid2 size={{xs:12,sm:12,lg:6}}>
           
            <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Shipping Address <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                        {/* <MaterialSelect
                   name={`input.shippingAddress`}
  value={
    Against.find((option) => option.value === values.input.shippingAddress) || null
  } // This ensures the value is correctly formatted as { value, label }
  options={Against.map((option) => ({
    value: option.value,
    label: option.label,
  }))} // Passes options correctly formatted
  onChange={(selectedOption) => {
    setFieldValue(`input.shippingAddress`, selectedOption ? selectedOption.value : ''); // Updates Formik field
  }}
  isClearable
/> */}

<MaterialSelect
                   name={`input.shippingAddress`}
  value={
    Address?{

      value: values.input.shippingAddress,
      label: values.input.shippingAddress
    }:null
  } 
  options={
    Object.entries(Address).map(([key, value]) => ({
      value: key,
      label: value,
    }))
  }
   // Passes options correctly formatted
  onChange={(selectedOption) => {
    console.log("seloptval",selectedOption)
    setFieldValue(`input.shippingAddress`, selectedOption ? selectedOption.label: ''); // Updates Formik field
  }}
  isClearable

  onBlur={() => setFieldTouched('input.shippingAddress', true)}

  error={
    touched.input?.shippingAddress && errors.input?.shippingAddress
      ? true
      : false
  }
  success={
    touched.input?.shippingAddress && !errors.input?.shippingAddress
      ? true
      : false
  }


/>

{touched.input?.shippingAddress && errors.input?.shippingAddress && (
    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
      {errors.input.shippingAddress}
    </div>
  )}
         

         </Grid2>

         <Grid2 size={{xs:12,sm:12,lg:6}}>
           
         <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Payment Terms <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>

                        <MaterialSelect
                   name={`input.paymentTerms`}
  value={
    Against.find((option) => option.value === values.input.paymentTerms) || null
  } // This ensures the value is correctly formatted as { value, label }
  options={Against.map((option) => ({
    value: option.value,
    label: option.label,
  }))} // Passes options correctly formatted
  onChange={(selectedOption) => {
    setFieldValue(`input.paymentTerms`, selectedOption ? selectedOption.value : ''); // Updates Formik field
  }}
  isClearable
  onBlur={() => setFieldTouched('input.paymentTerms', true)}

  error={touched.input?.paymentTerms && errors.input?.paymentTerms ? true : false} // Display error if touched and validation fails
  success={touched.input?.paymentTerms && !errors.input?.paymentTerms ? true : false}

/>

{touched.input?.paymentTerms && errors.input?.paymentTerms && (
    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
      {errors.input.paymentTerms}
    </div>
  )}

          </Grid2>

            </Grid2>
               {/* textarea and submitbutton */}
              <Grid2 size={{xs:12,sm:12,lg:12}} container spacing={1}>
                
              <Grid2 size={{xs:12,sm:12,lg:6}}>
                <Stack>

                
              <MaterialBox lineHeight={0} display="inline-block">
                            <MaterialTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                            >
                                Terms and Condition <span style={{color:"red"}}>*</span>
                            </MaterialTypography>
                        </MaterialBox>
                 <textarea style={{height:"20vh",padding:5}}
                 name={"input.termsAndConditions"}  {...getFieldProps("input.termsAndConditions")} 
                 >

                 </textarea>


                 </Stack>

                 {touched.input?.termsAndConditions && errors.input?.termsAndConditions && (
    <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>
      {errors.input.termsAndConditions}
    </div>
  )}

  {/* Display success message if validation is successful */}
  {touched.input?.termsAndConditions && !errors.input?.termsAndConditions && (
    <div style={{ color: 'green', fontSize: '12px', marginTop: '4px' }}>
      Looks good!
    </div>
  )}
                </Grid2>

                <Grid2 size={{xs:12,sm:12,lg:6}} sx={{paddingTop:20}}>
                  
                  <MaterialBox
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <MaterialButton
                              variant="gradient"
                              color="dark"
                              // onClick={handleDiscardButton}
                            >
                              Cancel
                            </MaterialButton>
                            <MaterialButton
                              sx={{ m: 2 }}
                              variant="gradient"
                              color="dark"
                              type="submit"
                              
                            >
                              Submit
                            </MaterialButton>

                            
                          </MaterialBox>

                </Grid2>

              </Grid2>

                 </Grid2>

      

            </MaterialBox>

                  </CardContent>

              </Card>

              <Dialog
      open={open}
      onClose={handleDialogClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "15px",
          padding: "20px",
          backgroundColor: "#f9f9f9",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
          <CheckCircleIcon style={{ color: "#4caf50", fontSize: "2rem" }} />
          <Typography variant="h5" style={{ fontWeight: "bold", color: "#333" }}>
            Confirm Submission
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            textAlign: "center",
            color: "#555",
            fontSize: "1.1rem",
            lineHeight: "1.5",
          }}
        >
          Are you sure you want to submit this form? Double-check all details before proceeding.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", marginTop: "20px" }}>
        <Button
          onClick={()=>setOpen(false)}
          variant="contained"
          startIcon={<CancelIcon />}
          style={{
            backgroundColor: "#f44336",
            color: "#fff",
            textTransform: "capitalize",
            padding: "8px 16px",
            fontWeight: "500",
            cursor:"pointer",
            borderRadius:"30px"
          }}
        >
          No, Go Back
        </Button>
        <Button
          onClick={handleConfirmSubmit}
          variant="contained"
          startIcon={<CheckCircleIcon />}
          style={{
            backgroundColor: "#4caf50",
            color: "#fff",
            textTransform: "capitalize",
            padding: "8px 16px",
            fontWeight: "500",
            cursor:"pointer",
            borderRadius:"30px"
          }}
        >
          Yes, Submit
        </Button>
      </DialogActions>
    </Dialog>
   

    <NotificationSnackbar
        open={notificationOpen}
        handleClose={handleNotificationClose}
        message={notificationMessage}
        type={notificationType}
      />

          </Form>


            )
        }
   





    }



    </Formik>

    </DashboardLayout>
    
    
 )

}
export default PurchaseOrder



