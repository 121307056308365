import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import EditIcon from "@mui/icons-material/Edit";
import MaterialSelect from "../../components/MasterSelect";
import { FaFolderClosed } from "react-icons/fa6";
import { FaFolderOpen } from "react-icons/fa";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { TreeView, TreeItem } from "@mui/lab";

import {
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Stack,
  Input,
  colors,
  TableContainer,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import request, { gql } from "graphql-request";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialButton from "../../components/MaterialButton";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import DescriptionIcon from "@mui/icons-material/Description";
import "../../assest/style/style.css";
import BASEURL from "../../pages/Utils/Baseurl";
import { chartList, GetLedgerHierarchy, viewparents } from "../../Api/ApiQuery";
import MaterialBox from "../../components/MaterialBox";
import MaterialInput from "../../components/MaterialInput";
import { components } from "react-select";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import { TreeList, Column } from "devextreme-react/tree-list";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import {
  createChildLedger,
  createLedger,
  createParentLedger,
  deleteledger,
  updateledger,
} from "../../Api/apiMutations";
import { useLoading } from "../../context/LoadingContext";

const CollapsibleTable = ({
  data,
  setConfirmationDialog,
  confirmationDialog,
  getList,
  setaddpopup,
  setLedger,
  ledger,
  setAddChild,
  setParentId,
  setEdit,
  addpopup,
  viewhandel,
}) => {
  const { circleLoading, setCirleLoading } = useLoading();
  const edithandeler = (id) => {
    setParentId(id);
    viewhandel(id);
    setaddpopup(!addpopup);
    setEdit(true);
  };
  const deletehandel = (id) => {
    setConfirmationDialog({
      open: true,
      title: "Confirmation message",
      message: "Are you sure do you want to delete parents details.",
      showCancel: true,
      showConfirm: true,

      onCancel: () => {
        setConfirmationDialog({
          ...confirmationDialog,
          open: false,
        });
      },
      onConfirm: () => {
        setCirleLoading(true);
        const response = request(BASEURL, deleteledger, {
          id: id,
        })
          .then((res) => {
            console.log(res);
            // setaddpopup(false);
            setCirleLoading(false);
            getList();
            setConfirmationDialog({
              open: true,
              title: "Success message",
              message: "Successfully Ledger Deleted",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
          })
          .catch((err) => {
            console.log(err);
            setCirleLoading(false);
            setConfirmationDialog({
              open: true,
              title: "Error message",
              message:
                "We are unable to fetch the required data at this time. Please try again later. If the problem persists, please contact support.",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
          });
      },
    });
  };
  const addChild = (id) => {
    setaddpopup(true);
    setParentId(id);
    setAddChild(true);
  };
  const renderRows = (items, level = 0) => {
    return items.map((item, index) => (
      <React.Fragment key={`${item.id}-${index}`}>
        <tr>
          <td style={{ paddingLeft: `${level * 20}px` }}>
            {item.children && (
              <button
                style={{
                  marginRight: "5px",
                  cursor: "pointer",
                  border: "none",
                  background: "transparent",
                  paddingLeft: "50px",
                }}
                onClick={() => toggleExpand(item.id)}
              >
                {expandedRows.includes(item.id) ? (
                  <FaFolderOpen size={20} color="gray" />
                ) : (
                  <FaFolderClosed size={18} color="gray" />
                )}
              </button>
            )}
          </td>
          <td>{item.ledgerId}</td>
          <td>{item.ledgerName}</td>
          <td>{item.component}</td>

          <td>
            <RemoveRedEyeIcon
              sx={{
                transform: "scale(1.5)",
                marginRight: "45px",
                cursor: "pointer",
                color: "gray",
              }}
              onClick={() => {
                edithandeler(item?.id);
              }}
            />

            {/* <DeleteIcon
              sx={{
                transform: "scale(1.5)",
                marginRight: "25px",
                cursor: "pointer",
                color: "red",
              }}
              onClick={() => {
                deletehandel(item?.id);
              }}
            /> */}
            {item?.parents !== "A_1" && (
              <AddIcon
                sx={{
                  transform: "scale(1.5)",
                  marginRight: "35px",
                  cursor: "pointer",
                  color: "green",
                }}
                onClick={() => {
                  addChild(item?.id);
                }}
              />
            )}
          </td>
        </tr>
        {item.children &&
          expandedRows.includes(item.id) &&
          renderRows(item.children, level + 1)}
      </React.Fragment>
    ));
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleExpand = (rowName) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(rowName)
        ? prevExpandedRows.filter((name) => name !== rowName)
        : [...prevExpandedRows, rowName]
    );
  };

  return (
    <div className="tabel-target">
      <table
        border="1"
        cellPadding="10"
        cellSpacing="0"
        style={{ width: "100%", borderCollapse: "collapse", border: "none" }}
      >
        <thead>
          <tr>
            <th></th>
            <th>Ledger Id</th>
            <th>Ledger Name</th>
            <th>Component</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{renderRows(data)}</tbody>
      </table>
    </div>
  );
};
const ChartedAccounts = () => {
  const [openRows, setOpenRows] = useState({});
  const { circleLoading, setCirleLoading } = useLoading();

  // const toggleRow = (id) => {
  //   setOpenRows((prevState) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };
  const expandedRowKeys = [1];
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [id, setId] = useState("");
  const [ledger, setLedger] = useState({
    ledgerId: "",
    name: "",
    component: "",
    parent: "",
    nameStatus: "unckecked",
    parentStatus: "unckecked",
    componentStatus: "unckecked",
  });
  const [pageState, setPageState] = useState({
    loading: false,
    error: false,
    message: "",
    state: "init",
  });
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    showCancel: true,
    showConfirm: true,
    customSection: null,
    onConfirm: () => {},
    onCancel: () => {},
    success: false,
  });
  const [index, setindex] = useState("");
  const [list, setList] = useState([]);
  const [addpopup, setaddpopup] = useState(false);
  const [addChild, setAddChild] = useState(false);
  const [parentId, setParentId] = useState("");
  const [btndis, setBtnDis] = useState(false);
  const navigate = useNavigate();

  // query

  const getList = () => {
    setCirleLoading(true);
    const response = request(BASEURL, GetLedgerHierarchy)
      .then((res) => {
        console.log(res?.ledgerHierarchy);
        const data = res?.ledgerHierarchy.sort((a, b) => a.id - b.id);
        // setList(res?.ledgerHierarchy);
        setList(data);
        setCirleLoading(false);
      })
      .catch((err) => {
        setCirleLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, []);

  const labels = [
    {
      value: "Income",
    },
    {
      value: "Expenses",
    },
    {
      value: "Asset",
    },
    {
      value: "Liabilities",
    },
  ];

  const type = [
    {
      value: "Group",
    },
    {
      value: "Ledger",
    },
  ];
  const handleproductDataChange = (event, selectEvent) => {
    // console.log('selectEvent:', selectEvent, event?.label)
    let productState = { ...ledger };
    if (event?.target === undefined && selectEvent) {
      if (selectEvent.name === "component") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          productState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          productState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }
      if (selectEvent.name === "parent") {
        const lengthRegex = new RegExp("^[A-Za-z0-9]");
        if (lengthRegex.test(event?.label) === true) {
          productState[selectEvent.name + "Status"] =
            event?.label === undefined ? "unchecked" : "success";
        } else {
          productState[selectEvent.name + "Status"] =
            selectEvent.value === "" ? "unchecked" : "error";
        }
      }
      productState[selectEvent.name] = event;
    } else {
      const changeAllowed = true;
      switch (event.target.name) {
        case "name":
          {
            const alphaNumberLengthRegex = new RegExp("^([\\s\\S]{1,35})$");

            if (alphaNumberLengthRegex.test(event.target.value?.trim())) {
              productState[event.target.name + "Status"] = "success";
            } else {
              productState[event.target.name + "Status"] =
                event.target.value === "" ? "unchecked" : "error";
            }
          }
          break;
          // case "component":
          {
            // const alphaNLengthRegex = new RegExp("^([a-zA-Z ]{1,5})$");
            // if (alphaNLengthRegex.test(event.target.value?.trim())) {
            //   productState[event.target.name + "Status"] = "success";
            // } else {
            //   productState[event.target.name + "Status"] =
            //     event.target.value === "" ? "unchecked" : "error";
            // }
          }
          break;
      }
      if (changeAllowed) {
        productState[event.target.name] = event.target.value;
      }
    }
    setLedger(productState);
  };

  const addParent = () => {
    const userdetails = localStorage.getItem("user");
    const userid = JSON.parse(userdetails);
    if (edit) {
      setConfirmationDialog({
        open: true,
        title: "Confirmation",
        message: "Are you sure do you want to Edit your details.",
        showCancel: true,
        showConfirm: true,
        onConfirm: () => {
          setCirleLoading(true);
          setBtnDis(true);
          const response = request(BASEURL, updateledger, {
            input: {
              id: parentId,
              ledgerName: ledger?.name,
              component: ledger?.component?.value,
              parents: ledger?.parent?.value == "Group" ? "0" : "1",
            },
          })
            .then((res) => {
              setCirleLoading(false);
              console.log(res);
              setaddpopup(false);
              setBtnDis(false);
              getList();
              const name = {
                name: "",
                component: "",
              };
              setLedger(name);
              setEdit(false);
              setConfirmationDialog({
                open: true,
                title: "Success message",
                message: "Successfully Added",
                showCancel: true,
                showConfirm: false,
                onCancel: () => {
                  setConfirmationDialog({
                    ...confirmationDialog,
                    open: false,
                  });
                },
              });
            })
            .catch((err) => {
              console.log(err);
              setCirleLoading(false);
              setBtnDis(false);
              setEdit(false);
            });
        },
        onCancel: () => {
          setConfirmationDialog({
            ...confirmationDialog,
            open: false,
          });
        },
      });
    } else if (addChild) {
      setConfirmationDialog({
        open: true,
        title: "Confirmation",
        message: "Are you sure do you want to Submit your details.",
        showCancel: true,
        showConfirm: true,
        onConfirm: () => {
          setBtnDis(true);
          const tosting = parentId.toString();
          const base65 = btoa(tosting);
          const response = request(BASEURL, createChildLedger, {
            input: {
              user: userid?.id,
              parentLedgerId: `VXNlck5vZGU6${base65}`,
              ledgerName: ledger?.name,
              component: ledger?.component?.value,
              parents: ledger?.parent?.value == "Group" ? "0" : "1",
            },
          })
            .then((res) => {
              console.log(res);
              setBtnDis(false);
              setaddpopup(false);
              getList();
              const name = {
                name: "",
                component: "",
              };
              setLedger(name);
              setAddChild(false);
              setCirleLoading(false);
              setConfirmationDialog({
                open: true,
                title: "Success message",
                message: "Successfully Added",
                showCancel: true,
                showConfirm: false,
                success: true,
                onCancel: () => {
                  setConfirmationDialog({
                    ...confirmationDialog,
                    open: false,
                  });
                },
              });
            })
            .catch((err) => {
              console.log(err);
              setCirleLoading(false);
              setaddpopup(false);
              setBtnDis(false);
              setConfirmationDialog({
                open: true,
                title: "Error message",
                message:
                  "Ledger already exist",
                showCancel: true,
                showConfirm: false,
                onCancel: () => {
                  setConfirmationDialog({
                    ...confirmationDialog,
                    open: false,
                  });
                },
              });
            });
        },
        onCancel: () => {
          setConfirmationDialog({
            ...confirmationDialog,
            open: false,
          });
        },
      });
    } else {
      setConfirmationDialog({
        open: true,
        title: "Confirmation",
        message: "Are you sure do you want to submit your details.",
        showCancel: true,
        showConfirm: true,
        onConfirm: () => {
          setBtnDis(true);
          const response = request(BASEURL, createParentLedger, {
            input: {
              user: userid?.id,
              ledgerName: ledger?.name,
              component: ledger?.component?.value,
              parents: ledger?.parent?.value == "Group" ? "0" : "1",
            },
          })
            .then((res) => {
              console.log(res);
              setaddpopup(false);
              setBtnDis(false);
              getList();
              const name = {
                name: "",
                component: "",
              };
              setLedger(name);
              setCirleLoading(false);
              setConfirmationDialog({
                open: true,
                title: "Success message",
                message: "Successfully Added",
                showCancel: true,
                showConfirm: false,
                success: true,
                onCancel: () => {
                  setConfirmationDialog({
                    ...confirmationDialog,
                    open: false,
                  });
                },
              });
            })
            .catch((err) => {
              console.log(err);
              setBtnDis(false);
              setaddpopup(false);
              setCirleLoading(false);
              setConfirmationDialog({
                open: true,
                title: "Error message",
                message:
                "Ledger already exist",
                showCancel: true,
                showConfirm: false,
                onCancel: () => {
                  setConfirmationDialog({
                    ...confirmationDialog,
                    open: false,
                  });
                },
              });
            });
        },
        onCancel: () => {
          setConfirmationDialog({
            ...confirmationDialog,
            open: false,
          });
        },
      });
    }
  };
  const edithandeler = (id) => {
    setId(id);
    setaddpopup(!addpopup);
    setEdit(true);
  };

  const deletehandel = (id) => {
    setConfirmationDialog({
      open: true,
      title: "Confirmation massage",
      message: "Are you sure do you want to delete parents details.",
      showCancel: true,
      showConfirm: true,

      onCancel: () => {
        setConfirmationDialog({
          ...confirmationDialog,
          open: false,
        });
      },
      onConfirm: () => {
        const response = request(BASEURL, deleteledger, {
          id: id,
        })
          .then((res) => {
            console.log(res);
            // setaddpopup(false);
            getList();
            setConfirmationDialog({
              ...confirmationDialog,
              open: false,
            });
          })
          .catch((err) => {
            console.log(err);
            setConfirmationDialog({
              open: true,
              title: "Error message",
              message:
                "We are unable to fetch the required data at this time. Please try again later. If the problem persists, please contact support.",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
          });
      },
    });
  };

  const viewhandel = (id) => {
    setCirleLoading(true);
    const response = request(BASEURL, viewparents, {
      id: id,
    })
      .then((res) => {
        console.log(res);
        setCirleLoading(false);
        // setaddpopup(false);
        const data = res?.getLedgerById;
        const viewData = {
          ledgerId: data?.ledgerId,
          name: data?.ledgerName,
          component: { label: data?.component, value: data?.component },
          nameStatus: "success",
          componentStatus: "success",
        };
        setLedger(viewData);
        getList();
        setConfirmationDialog({
          ...confirmationDialog,
          open: false,
        });
      })
      .catch((err) => {
        console.log(err);
        setCirleLoading(false);
        setConfirmationDialog({
          open: true,
          title: "Error message",
          message:
            "We are unable to fetch the required data at this time. Please try again later. If the problem persists, please contact support.",
          showCancel: true,
          showConfirm: false,
          onCancel: () => {
            setConfirmationDialog({
              ...confirmationDialog,
              open: false,
            });
          },
        });
      });
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleExpand = (rowName) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(rowName)
        ? prevExpandedRows.filter((name) => name !== rowName)
        : [...prevExpandedRows, rowName]
    );
  };
  console.log(btndis);
  const disabled = () => {
    if (
      ledger?.nameStatus == "success" &&
      ledger?.parentStatus == "success" &&
      ledger?.componentStatus == "success"
    ) {
      setBtnDis(false);
    } else {
      setBtnDis(true);
    }
  };
  useEffect(() => {
    disabled();
  }, [ledger]);
  return (
    <DashboardLayout>
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          showCancel={confirmationDialog.showCancel}
          showConfirm={confirmationDialog.showConfirm}
          customSection={confirmationDialog.customSection}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={confirmationDialog.onCancel}
          success={confirmationDialog.success}
        />
      )}
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Chart of Accounts
                </MaterialTypography>
                {/* <MaterialTypography variant="caption" color="text">
                  JOURNALS
                </MaterialTypography> */}
              </Stack>

              <MaterialButton
                variant="gradient"
                color="dark"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => setaddpopup(true)}
              >
                Add Ledger
              </MaterialButton>
            </Stack>
          }
        />
        {addpopup && (
          <Dialog
            maxWidth="sm"
            style={{ maxHeight: "566px" }}
            fullWidth
            // {...rest}
            open={true}
          >
            <DialogTitle>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MaterialTypography variant="body1">
                  {edit ? "View Ledger" : addChild ? "Add Child" : "Add Ledger"}
                </MaterialTypography>
              </div>
            </DialogTitle>
            <DialogContent
              style={{
                minHeight: `${edit ? "200px" : "350px"}`,
                maxHeight: "max-content",
              }}
              variant="body2"
            >
              <div>
                {edit && (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "30%" }}>
                      <label style={{ color: "black" }}>
                        Ledger Id:<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    {/* </MaterialBox> */}
                    <div style={{ width: "60%" }}>
                      <MaterialInput
                        // disabled={edit == true ? false : true}
                        name="product_name"
                        value={ledger?.ledgerId}
                        disabled
                        // onChange={(e) => setToken(e.target.value)}
                        placeholder="eg.Ledger Id"
                      />
                    </div>
                  </div>
                )}
                <div style={{ display: "flex", marginTop: "25px" }}>
                  <div style={{ width: "30%" }}>
                    <label style={{ color: "black" }}>
                      Ledger Name:<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  {/* </MaterialBox> */}
                  <div style={{ width: "60%" }}>
                    <MaterialInput
                      disabled={edit == true ? true : false}
                      name="name"
                      value={ledger.name}
                      onChange={handleproductDataChange}
                      placeholder="eg.Ledger Name"
                      error={ledger.nameStatus === "error" ? true : false}
                      success={ledger.nameStatus === "success" ? true : false}
                    />
                  </div>
                </div>
                {!edit && (
                  <div style={{ display: "flex", marginTop: "25px" }}>
                    <div style={{ width: "30%" }}>
                      <label style={{ color: "black" }}>
                        Schedule:<span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    {/* </MaterialBox> */}
                    <div style={{ width: "60%" }}>
                      <MaterialSelect
                        // disabled={edit == true ? false : true}
                        name="parent"
                        value={ledger?.parent}
                        onChange={handleproductDataChange}
                        placeholder="eg.Select Schedule"
                        options={type.map((value) => {
                          return {
                            value: value.value,
                            label: value.value,
                          };
                        })}
                        error={ledger.parentStatus === "error" ? true : false}
                        success={
                          ledger.parentStatus === "success" ? true : false
                        }
                      />
                    </div>
                  </div>
                )}
                <div style={{ display: "flex", marginTop: "25px" }}>
                  <div style={{ width: "30%" }}>
                    <label style={{ color: "black" }}>
                      Component:<span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  {/* </MaterialBox> */}
                  <div style={{ width: "60%" }}>
                    <MaterialSelect
                      name="component"
                      value={ledger?.component}
                      onChange={handleproductDataChange}
                      placeholder="eg.Select Component"
                      options={labels.map((value) => {
                        return {
                          value: value.value,
                          label: value.value,
                        };
                      })}
                      error={ledger.componentStatus === "error" ? true : false}
                      success={
                        ledger.componentStatus === "success" ? true : false
                      }
                      isDisabled={edit == true ? true : false}
                    />
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <MaterialButton
                color="error"
                variant="text"
                onClick={() => {
                  setaddpopup(false);
                  setEdit(false);
                  setLedger({});
                }}
              >
                Cancel
              </MaterialButton>
              {!edit && (
                <MaterialButton
                  color="dark"
                  variant="text"
                  onClick={addParent}
                  disabled={btndis}
                >
                  submit
                </MaterialButton>
              )}
            </DialogActions>
          </Dialog>
        )}

        <CollapsibleTable
          data={list}
          setConfirmationDialog={setConfirmationDialog}
          confirmationDialog={confirmationDialog}
          getList={getList}
          setaddpopup={setaddpopup}
          setLedger={setLedger}
          ledger={ledger}
          setAddChild={setAddChild}
          setParentId={setParentId}
          setEdit={setEdit}
          addpopup={addpopup}
          viewhandel={viewhandel}
        />
      </Card>
    </DashboardLayout>
  );
};

export default ChartedAccounts;
