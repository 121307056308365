// import React, { useEffect, useState } from "react";
// import DashboardLayout from "../../layoutcontainers/DashboardLayout";
// import {
//   Box,
//   ButtonGroup,
//   Card,
//   CardContent,
//   CardHeader,
//   Divider,
//   FormControl,
//   FormHelperText,
//   Icon,
//   Select,
//   Stack,
// } from "@mui/material";
// import Grid from "@mui/material/Grid2";

// import MaterialTypography from "../../components/MaterialTypography";
// import { BsFillPersonVcardFill } from "react-icons/bs";
// import MaterialBox from "../../components/MaterialBox";
// import MaterialInput from "../../components/MaterialInput";
// import MaterialSelect from "../../components/MasterSelect";
// import "../../assest/style/style.css";
// import MaterialButton from "../../components/MaterialButton";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import { Receipt } from "@mui/icons-material";
// import request from "graphql-request";
// import BASEURL from "../../pages/Utils/Baseurl";

// import { allCustomer, allUsersDetail, allVendor, getbilldate$amt, getBillno } from "../../Api/ApiQuery";
// const Payment = () => {


//   const today = new Date().toISOString().split("T")[0];
//   const [billing, setBilling] = useState([
//     {
//       type:"",
//       bill_no: {label:"",value:""},
//       bill_date: "",
//       bill_amount: "",
//       outstanding: "",
//       allocating: "",
//       typeStatus: "unchecked",
//       bill_noStatud: "unchecked",
//     },
//   ]);
//   const [disabled, setDisabled] = useState(false);

//   // const [deduction, setDeduction] = useState([
//   //   {
//   //     account: "",
//   //     cost: "",
//   //     amount: "",

//   //     accountStatus: "unchecked",
//   //     cost: "unchecked",
//   //   },
//   // ]);

//   const [paymentdel, setPaymentdel] = useState({
   
//     against: {label:"",value:""},
//     Receipt_date: today,
//     businees_par: "",
//     payment_mode: {
//       label: "Accounts Transfer",
//       value: "Accounts Transfer",
//     },
//     name_of_bank: "",
//     payment_amount: "",
//     cheque_num: "",
//     cheque_date: "",
//     payment_reference: "",
//     remarks: "",

//     voucher_nameStatus: "unchecked",
//     againstStatus: "unchecked",
//     Receipt_dateStatus: "unchecked",
//     businees_parStatus: "unchecked",
//     payment_modeStatus: "unchecked",
//     name_of_bankStatus: "unchecked",
//     payment_amountStatus: "unchecked",
//     cheque_numStatus: "unchecked",
//     cheque_dateStatus: "unchecked",
//     payment_referenceStatus: "unchecked",
//     remarksStatus: "unchecked",
//   });

//   const [vendor, setVendor] = useState([]);
//   const [customer, setCustomer] = useState([]);
//   const [employe, setEmployee] = useState([]);

//   const [Billnos,setBillnos] = useState([])

//   const addFunction = () => {
//     const temp = [...billing];
//     temp.push({
//       type: "",
//       bill_no: "",
//       bill_date: "",
//       bill_amount: "",
//       outstanding: "",
//       allocating: "",
//     });
//     setBilling(temp);
//   };
//   const deleteFunction = (ind) => {
//     console.log(ind);
//     const temp = [...billing];
//     temp.splice(ind, 1);
//     console.log(temp);
//     setBilling(temp);
//   };

//   const handeltabel = (e, selectEvent, ind) => {
//     if(selectEvent=="allocating"){
//       console.log(e.target.value)
//       // const temp = [...billing];
//       // const 
//       // temp[ind] = {
//       //    ...temp[ind],
//       //    [selectEvent]: e.target.value,
//       //    ["outstanding"] :
//       // }

//       // setBilling(temp)
//       console.log("billing detail",billing)
//     }
//    else{
//     console.log("hello")
//     console.log(e, ind);
//       console.log("selectevent",selectEvent)
//     const temp = [...billing];
//     if (selectEvent&&e!=null) {
//       console.log("inside if")
      
//       temp[ind] = {
//         ...temp[ind],
//         [selectEvent]: e,
//         [selectEvent + `Status`]: "success",
//       };
//       if (selectEvent == "type"){

//          if(e.value == "Purchase Order"){
//           console.log("purchase trigered for bill no ")
          
           
 
//              const response = request(BASEURL, getBillno)
//               .then((res) => {
//                console.log("all bill nos",res.allPurchase);

//                const dummybillnos = res.allPurchase
//                 setBillnos(dummybillnos)
//               })

         
//         }
//          else{
//           console.log("removing the billnos")
//           setBillnos([])
//          }

//         console.log("bill no trigered")
//       }
//       else if(selectEvent == "bill_no"){
      
//         const response = request(BASEURL, getbilldate$amt, {
//           id: temp[ind].bill_no.value,
//         })
//           .then((res) => {
//             console.log(res);
//             console.log(res.purchase);
           

//             temp[ind] = {
//               ...temp[ind],
//               ["bill_date"]: res.purchase.orderDate,
//               ["bill_amount"]:res.purchase.totalamount,
//               [selectEvent + `Status`]: "success",
//             };

//           })
//           .catch((err) => {
//             console.log(err);
//           });

//          console.log("bill_no is about to triger bill data and amount")




//       }
//     } else {
//       console.log("inside else")
//       temp[ind] = {
//         ...temp[ind],
//         [selectEvent]: "",
//         ["bill_no"]:"",
//         // [e.target.name]: e.target.value,
//         [selectEvent + `Status`]: "error",
       
//       };
//       setBillnos([])

      
     
//     }

//     setBilling(temp);
//     console.log("billing detail",billing)
//   }
//   };

  

//   //  useEffect( ()=>{

      
//   //   const getallbillno = async ()=>{
//   //     if (!Billnos || Billnos.length === 0){ 
//   //       setBillnos([])
        
//   //       return}; 
//   //     console.log("useeffect trigered")
//   //     const response = request(BASEURL, getBillno)
//   //     .then((res) => {
//   //       console.log("all bill nos",res.allPurchase);

//   //       const dummybillnos = res.allPurchase
//   //       setBillnos(dummybillnos)
//   //       // setBillnos(res?.allUsersDetail);
//   //       // setVendor(res?.allVendor);
//   //     })
//   //     .catch((err) => {
//   //       console.log(err);
//   //     });



//   //   }

//   //   getallbillno()

//   //  },[Billnos]



//   //  )

//   console.log(billing, "billing");

//   const Against = [
//     {
//       value: "Payment to Vendor",
//       label: "Payment to Vendor",
//     },
//     {
//       value: "Refund to Customer",
//       label: "Refund to Customer",
//     },
//     {
//       value: "Payment to Employee",
//       label: "Payment to Employee",
//     },
//   ];
//   const payment = [
//     {
//       value: "Cash",
//       label: "Cash",
//     },
//     {
//       value: "Cheque",
//       label: "Cheque",
//     },
//     {
//       value: "Accounts Transfer",
//       label: "Accounts Transfer",
//     },
//   ];
//   // onchange handelor

//   const handelOnchange = (event, selectEvent) => {
//     let temp = { ...paymentdel };

//     if (event?.target === undefined && selectEvent) {
//       switch (selectEvent.name) {
//         case "against": {
//           if (event) {
//             console.log("event",event.label)
//             console.log("selectevent",selectEvent)
//             // setPaymentdel((prev) => ({ ...prev, against: event.label }));

//             temp[selectEvent?.name + "Status"] = "success";


//           } else {
//             temp[selectEvent?.name + "Status"] =
//               selectEvent.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//         case "businees_par": {
//           if (event) {
//             temp[selectEvent?.name + "Status"] = "success";
//           } else {
//             temp[selectEvent?.name + "Status"] =
//               selectEvent.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//         case "payment_mode": {
//           if (event) {
//             temp[selectEvent?.name + "Status"] = "success";
//           } else {
//             temp[selectEvent?.name + "Status"] =
//               selectEvent.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//       }
//       temp[selectEvent.name] = event;
//     } else {
//       switch (event?.target.name) {
//         case "payment_amount": {
//           if (/^[0-9]{1,7}$/.test(event?.target.value)) {
//             temp[event?.target.name + "Status"] = "success";
//           } else {
//             temp[event?.target.name + "Status"] =
//               event?.target?.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//         case "payment_reference": {
//           if (/^[a-zA-Z0-9\s]{1,25}$/.test(event?.target.value)) {
//             temp[event?.target.name + "Status"] = "success";
//           } else {
//             temp[event?.target.name + "Status"] =
//               event?.target?.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//         case "cheque_num": {
//           if (/^[0-9]{1,7}$/.test(event?.target.value)) {
//             temp[event?.target.name + "Status"] = "success";
//           } else {
//             temp[event?.target.name + "Status"] =
//               event?.target?.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//         case "cheque_date": {
//           if (event?.target.value) {
//             temp[event?.target.name + "Status"] = "success";
//           } else {
//             temp[event?.target.name + "Status"] =
//               event?.target?.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//         case "remarks": {
//           if (/^[a-zA-Z0-9\s]{1,250}$/.test(event?.target.value)) {
//             temp[event?.target.name + "Status"] = "success";
//           } else {
//             temp[event?.target.name + "Status"] =
//               event?.target?.value === "" ? "unchecked" : "error";
//           }
//           break;
//         }
//       }
//       temp[event?.target.name] = event?.target.value;
//     }
//     setPaymentdel(temp);
//   };

//   console.log(paymentdel, "paymentdel");

//   const getallvendor = () => {
//     const response = request(BASEURL, allVendor)
//       .then((res) => {
//         setVendor(res?.allVendor);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   const getallCustomer = () => {
//     const response = request(BASEURL, allCustomer)
//       .then((res) => {
//         // console.log(res);
//         setCustomer(res?.allCustomer);
//         // setVendor(res?.allVendor);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
//   const getallEmplu = () => {
//     const response = request(BASEURL, allUsersDetail)
//       .then((res) => {
//         // console.log(res);
//         setEmployee(res?.allUsersDetail);
//         // setVendor(res?.allVendor);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   useEffect(() => {
//     getallvendor();
//     getallCustomer();
//     getallEmplu();
//   }, []);

//   const type = [
//     {
//       value: "Purchase Order",
//       label: "Purchase Order",
//     },
//     {
//       value: "Purchase Bill",
//       label: "Purchase Bill",
//     },
//     {
//       value: "Bills Outstanding",
//       label: "Bills Outstanding",
//     },
//     {
//       value: "Debit Note",
//       label: "Debit Note",
//     },
//   ];

//   const customerType = [
//     {
//       value: "Sales  Invoice",
//       label: "Sales  Invoice",
//     },
//     {
//       value: "Performa Invoice",
//       label: "Performa Invoice",
//     },
//     {
//       value: "Invoice Outstanding",
//       label: "Invoice Outstanding",
//     },
//     {
//       value: "Credit Note",
//       label: "Credit Note",
//     },
//   ];

//   const employeType = [
//     {
//       value: "Employees",
//       label: "Employees",
//     },
//   ];

//   useEffect(()=>{
//     const loddet  = ()=>{
//     console.log(paymentdel)
//      }
//        loddet()
//     }


//   ,[paymentdel]);
//   const disabledHandel = () => {
//     let disabledstatus = false;
//     if (
//       billing?.typeStatus == "success" &&
//       billing?.bill_noStatud == "success" &&
//       // deduction?.accountStatus == "success" &&
//       // deduction?.cost == "success" &&
//       paymentdel?.againstStatus == "success" &&
//       paymentdel?.businees_parStatus == "success" &&
//       paymentdel?.payment_modeStatus == "success" &&
//       paymentdel?.payment_amountStatus == "success"
//     ) {
//       disabledstatus = false;
//     } else {
//       disabledstatus = true;
//     }

//     return disabledstatus;
//   };

//   return (
//     <DashboardLayout>
//       <Card>
//         <CardHeader
//           title={
//             <>
//               <Stack
//                 direction="row"
//                 justifyContent="space-between"
//                 alignItems="center"
//                 spacing={2}
//               >
//                 <Stack direction="column" spacing={0}>
//                   <MaterialTypography variant="h5" fontWeight="medium">
//                     Add Payment
//                   </MaterialTypography>
//                 </Stack>
//               </Stack>
//             </>
//           }
//         />
//         <CardContent sx={{ minHeight: "70vh" }}>
//           <Stack direction="row" spacing={1}>
//             <BsFillPersonVcardFill style={{ fontSize: 20 }} />
//             <MaterialTypography
//               component="label"
//               variant="body2"
//               fontWeight="bold"
//             >
//               Payment Details
//             </MaterialTypography>
//           </Stack>
//           <MaterialBox>
//             <Grid container spacing={2}>
           
//               <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Against <span style={{ color: "red" }}>*</span>
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialSelect
//                   name="against"
//                   value={paymentdel.against}
//                   defaultValue={{ label: "Please select" }}
//                   customStyles={{ optionPadding: 20 }}
//                   onChange={handelOnchange}
//                   options={Against?.map((value) => {
//                     return {
//                       value: value?.value,
//                       label: value?.label,
//                     };
//                   })}
//                   error={paymentdel?.againstStatus === "error" ? true : false}
//                   success={
//                     paymentdel?.againstStatus === "success" ? true : false
//                   }
//                   // isDisabled={togglePage?.view === true ? true : false}
//                   isClearable
//                 />
//               </Grid>
//               <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Receipt Date <span style={{ color: "red" }}>*</span>
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialInput
//                   type="date"
//                   name="Receipt_date"
//                   placeholder="Enter voucher name"
//                   value={paymentdel.Receipt_date}
//                   onChange={handelOnchange}
//                   error={
//                     paymentdel.Receipt_dateStatus === "error" ? true : false
//                   }
//                   success={
//                     paymentdel.Receipt_dateStatus === "success" ? true : false
//                   }
//                   disabled
//                 />
//               </Grid>
//               <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Business Partner <span style={{ color: "red" }}>*</span>
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialSelect
//                   name="businees_par"
//                   value={paymentdel?.businees_par}
//                   defaultValue={{ label: "Please Select" }}
//                   customStyles={{ optionPadding: 20 }}
//                   onChange={handelOnchange}
//                   options={
//                     paymentdel?.against?.value == "Payment to Vendor"
//                       ? vendor?.map((value) => {
//                           return {
//                             value: value?.id,
//                             label: value?.legalName,
//                           };
//                         })
//                       : paymentdel?.against?.value == "Refund to Customer"
//                       ? customer?.map((value) => {
//                           return {
//                             value: value?.id,
//                             label: value?.legalName,
//                           };
//                         })
//                       : paymentdel?.against?.value == "Payment to Employee"
//                       ? employe?.map((value) => {
//                           return {
//                             value: value?.id,
//                             label: value?.legalName,
//                           };
//                         })
//                       : []
//                   }
//                   error={
//                     paymentdel?.businees_parStatus === "error" ? true : false
//                   }
//                   success={
//                     paymentdel?.businees_parStatus === "success" ? true : false
//                   }
//                   // isDisabled={togglePage?.view === true ? true : false}
//                   isClearable
//                 />
//               </Grid>
//               <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Payment Mode <span style={{ color: "red" }}>*</span>
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialSelect
//                   name="payment_mode"
//                   value={paymentdel?.payment_mode}
//                   defaultValue={{ label: "Please Select" }}
//                   customStyles={{ optionPadding: 20 }}
//                   onChange={handelOnchange}
//                   options={payment?.map((value) => {
//                     return {
//                       value: value?.value,
//                       label: value?.label,
//                     };
//                   })}
//                   error={
//                     paymentdel.payment_modeStatus === "error" ? true : false
//                   }
//                   success={
//                     paymentdel.payment_modeStatus === "success" ? true : false
//                   }
//                   // isDisabled={togglePage?.view === true ? true : false}
//                   isClearable
//                 />
//               </Grid>
//               <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Name of the Bank/Cash{" "}
//                     <span style={{ color: "red" }}>*</span>
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialInput
//                   name="name_of_bank"
//                   placeholder="Enter Name of the Bank/Cash"
//                   value={
//                     paymentdel?.payment_mode?.value == "Cash"
//                       ? "11200"
//                       : "11300"
//                   }
//                   onChange={handelOnchange}
//                   // onChange={handleleadandProspectData}
//                   error={
//                     paymentdel.name_of_bankStatus === "error" ? true : false
//                   }
//                   success={
//                     paymentdel.name_of_bankStatus === "success" ? true : false
//                   }
//                   disabled
//                 />
//               </Grid>
//               <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Payment Amount <span style={{ color: "red" }}>*</span>
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialInput
//                   name="payment_amount"
//                   type="number"
//                   placeholder="Enter payment Amount"
//                   value={paymentdel.payment_amount}
//                   onChange={handelOnchange}
//                   error={
//                     paymentdel.payment_amountStatus === "error" ? true : false
//                   }
//                   success={
//                     paymentdel.payment_amountStatus === "success" ? true : false
//                   }
//                 />
//               </Grid>
//               {paymentdel?.payment_mode?.label == "Cheque" && (
//                 <>
//                   <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                     <MaterialBox lineHeight={0} display="inline-block">
//                       <MaterialTypography
//                         component="label"
//                         variant="caption"
//                         fontWeight="bold"
//                       >
//                         Cheque Number <span style={{ color: "red" }}>*</span>
//                       </MaterialTypography>
//                     </MaterialBox>
//                     <MaterialInput
//                       name="cheque_num"
//                       placeholder="Enter Cheque Number"
//                       value={paymentdel.cheque_num}
//                       onChange={handelOnchange}
//                       error={
//                         paymentdel.cheque_numStatus === "error" ? true : false
//                       }
//                       success={
//                         paymentdel.cheque_numStatus === "success" ? true : false
//                       }
//                     />
//                   </Grid>
//                   <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                     <MaterialBox lineHeight={0} display="inline-block">
//                       <MaterialTypography
//                         component="label"
//                         variant="caption"
//                         fontWeight="bold"
//                       >
//                         Cheque Date <span style={{ color: "red" }}>*</span>
//                       </MaterialTypography>
//                     </MaterialBox>
//                     <MaterialInput
//                       type="date"
//                       name="cheque_date"
//                       placeholder="Enter voucher name"
//                       value={paymentdel.cheque_date}
//                       onChange={handelOnchange}
//                       error={
//                         paymentdel.cheque_dateStatus === "error" ? true : false
//                       }
//                       success={
//                         paymentdel.cheque_dateStatus === "success"
//                           ? true
//                           : false
//                       }
//                     />
//                   </Grid>
//                 </>
//               )}
//               <Grid size={{ xs: 5, sm: 3, lg: 8 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Payment Reference
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialInput
//                   name="payment_reference"
//                   placeholder="Enter Payment Reference"
//                   value={paymentdel.payment_reference}
//                   // onChange={handleleadandProspectData}
//                   onChange={handelOnchange}
//                   error={
//                     paymentdel.payment_referenceStatus === "error"
//                       ? true
//                       : false
//                   }
//                   success={
//                     paymentdel.payment_referenceStatus === "success"
//                       ? true
//                       : false
//                   }
//                 />
//               </Grid>
//               <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Remarks
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <MaterialInput
//                   name="remarks"
//                   placeholder="Enter Remarks"
//                   value={paymentdel.remarks}
//                   // onChange={handleleadandProspectData}
//                   onChange={handelOnchange}
//                   error={paymentdel.remarksStatus === "error" ? true : false}
//                   success={
//                     paymentdel.remarksStatus === "success" ? true : false
//                   }
//                 />
//               </Grid>
//             </Grid>
//           </MaterialBox>
//           <Divider />
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <Stack direction="row" spacing={1}>
//               <BsFillPersonVcardFill style={{ fontSize: 20 }} />
//               <MaterialTypography
//                 component="label"
//                 variant="body2"
//                 fontWeight="bold"
//               >
//                 Bill Details
//               </MaterialTypography>
//             </Stack>
//             <Stack direction="row" spacing={1}>
//               <MaterialButton
//                 sx={{ m: 2 }}
//                 variant="gradient"
//                 color="dark"
//                 Icon={<AddIcon />}
//                 onClick={() => {
//                   addFunction();
//                 }}
//               >
//                 Add Billing
//               </MaterialButton>
//             </Stack>
//           </div>

//           <MaterialBox>
//             <div className="tabel-target">
//               <table
//                 border="1"
//                 cellPadding="10"
//                 cellSpacing="0"
//                 style={{
//                   width: "100%",
//                   borderCollapse: "collapse",
//                   border: "none",
//                 }}
//               >
//                 <thead>
//                   <tr>
//                     <th>Type</th>
//                     <th>Bill No</th>
//                     <th>Bill Date</th>
//                     <th>Bill Amount</th>
//                     <th>Outstanding Amount</th>
//                     <th>Allocating Amount</th>
//                     <th>Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {billing.map((item, ind) => {
//                     return (
//                       <tr>
//                         <td style={{ width: "430px" }}>
//                           {/* <select
//                             name="type"
//                             // defaultValue={v}
//                             onChange={(e) => {
//                               handeltabel(e, "type", ind);
//                             }}
//                             options={
//                               paymentdel?.against== "Payment to Vendor"
//                                 ? type?.map((value) => {
//                                     return {
//                                       value: value?.value,
//                                       label: value?.label,
//                                     };
//                                   })
//                                 : paymentdel?.against ==
//                                   "Refund to Customer"
//                                 ? customerType?.map((value) => {
//                                     return {
//                                       value: value?.value,
//                                       label: value?.label,
//                                     };
//                                   })
//                                 : paymentdel?.against ==
//                                   "Payment to Employee"
//                                 ? employeType?.map((value) => {
//                                     return {
//                                       value: value?.value,
//                                       label: value?.label,
//                                     };
//                                   })
//                                 : []
//                             }
//                             value={item?.type}
//                             error={item.typeStatus === "error" ? true : false}
//                             success={
//                               item?.typeStatus === "success" ? true : false
//                             }
//                           ></select> */}



//           <MaterialSelect
//                   name="type"
//                   value={item?.type}
//                   defaultValue={{ label: "Please select" }}
//                   customStyles={{ optionPadding: 20 }}
//                   onChange={(e)=> handeltabel(e, "type", ind)}
//                   options={
//                     (paymentdel?.against?.value||"")== "Payment to Vendor"
//                                 ? type?.map((value) => {
//                                     return {
//                                       value: value?.value,
//                                       label: value?.label,
//                                     };
//                                   })
//                                 : (paymentdel?.against?.value||"") ==
//                                   "Refund to Customer"
//                                 ? customerType?.map((value) => {
//                                     return {
//                                       value: value?.value,
//                                       label: value?.label,
//                                     };
//                                   })
//                                 : (paymentdel?.against?.value||"") ==
//                                   "Payment to Employee"
//                                 ? employeType?.map((value) => {
//                                     return {
//                                       value: value?.value,
//                                       label: value?.label,
//                                     };
//                                   })
//                                 : []
                
                
//                 }
//                 error={item.typeStatus === "error" ? true : false}
//                 success={
//                   item?.typeStatus === "success" ? true : false
//                 }
//                   // isDisabled={togglePage?.view === true ? true : false}
//                   isClearable
//                 />
                       



//                         </td>
//                         <td style={{ width: "430px" }}>
//                           <MaterialSelect
//                             name="bill_no"
//                             onChange={(e) => {
//                               handeltabel(e, "bill_no", ind);
//                             }}
//                             value={item?.bill_no}
//                             options={
//                                Billnos?.map((value)=>{

//                                 return {
//                                   value: value?.id,
//                                   label: value?.purchaseOrderid,
//                                 };

//                                })

//                             }
//                           >


//                           </MaterialSelect>
//                         </td>
//                         <td style={{ width: "430px" }}>
//                           <MaterialInput
//                             name="bill_date"
//                             type="date"
//                             onChange={(e) => {
//                               handeltabel(e, ind);
//                             }}
//                             value={item?.bill_date}
//                           ></MaterialInput>
//                         </td>
//                         <td style={{ width: "230px" }}>
//                           <MaterialInput
//                             name="bill_amount"
//                             onChange={(e) => {
//                               handeltabel(e, ind);
//                             }}
//                             value={item?.bill_amount}
//                           ></MaterialInput>
//                         </td>
//                         <td style={{ width: "230px" }}>
//                           <MaterialInput
//                             name="outstanding"
//                             // onChange={(e) => {
//                             //   handeltabel(e, ind);
//                             // }}
//                             value={item?.outstanding}
//                           ></MaterialInput>
//                         </td>
//                         <td
//                            style={{ width: "30px" }}
//                         >
//                           <MaterialInput
//                             name="allocating"
//                             onChange={(e) => {
//                               handeltabel(e,"allocating", ind);
//                             }}
//                             value={item?.allocating}
//                           ></MaterialInput>
//                         </td>
//                         <td>
//                           <DeleteIcon
//                             sx={{
//                               transform: "scale(1.5)",
//                               marginLeft: "10px",
//                               cursor: "pointer",
//                               color: "red",
//                             }}
//                             onClick={() => {
//                               deleteFunction(ind);
//                             }}
//                           />
//                         </td>
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//                 {/* <tbody>{renderRows(data)}</tbody> */}
//               </table>
//             </div>
//           </MaterialBox>
//           <Divider />
//           {/* <Stack direction="row" spacing={1}>
//             <BsFillPersonVcardFill style={{ fontSize: 20 }} />
//             <MaterialTypography
//               component="label"
//               variant="body2"
//               fontWeight="bold"
//             >
//               Deduction / Loss
//             </MaterialTypography>
//           </Stack> */}
         
//           <Divider />
//           <Stack direction="row" spacing={1}>
//             <BsFillPersonVcardFill style={{ fontSize: 20 }} />
//             <MaterialTypography
//               component="label"
//               variant="body2"
//               fontWeight="bold"
//             >
//               Additional Informations
//             </MaterialTypography>
//           </Stack>
//           <Grid container spacing={2}>
//             <Grid size={{ xs: 5, sm: 3, lg: 8 }}>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   width: "50%",
//                   paddingLeft: "20px",
//                   marginTop: "20px",
//                 }}
//               >
//                 <MaterialBox lineHeight={0} display="inline-block">
//                   <MaterialTypography
//                     component="label"
//                     variant="caption"
//                     fontWeight="bold"
//                   >
//                     Notes{" "}
//                   </MaterialTypography>
//                 </MaterialBox>
//                 <textarea
//                   style={{
//                     minHeight: "120px",
//                     maxHeight: "120px",
//                     maxWidth: "100%",
//                     minWidth: "100%",
//                     border: "1px solid gray",
//                     borderRadius: "5px",
//                     padding: "15px",
//                     marginTop: "10px",
//                   }}
//                   placeholder="Additional Informations"
//                   // onChange={(e) => setAddition(e.target.value)}
//                 />
//               </div>
//             </Grid>
//             <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
//               <MaterialBox lineHeight={0} display="inline-block">
//                 <MaterialTypography
//                   component="label"
//                   variant="caption"
//                   fontWeight="bold"
//                 >
//                   Attachment{" "}
//                 </MaterialTypography>
//               </MaterialBox>
//             </Grid>
//           </Grid>
//         </CardContent>
//         <MaterialBox
//           display="flex"
//           justifyContent="flex-end"
//           alignItems="center"
//         >
//           <MaterialButton
//             variant="gradient"
//             color="dark"
//             // onClick={handleDiscardButton}
//           >
//             Cancel
//           </MaterialButton>
//           <MaterialButton
//             sx={{ m: 2 }}
//             variant="gradient"
//             color="dark"
//             disabled={disabledHandel}
//             // onClick={AddInvoice}
//           >
//             Submit
//           </MaterialButton>
//         </MaterialBox>
//       </Card>
//     </DashboardLayout>
//   );
// };

// export default Payment;




import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Icon,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MaterialTypography from "../../components/MaterialTypography";
import { BsFillPersonVcardFill } from "react-icons/bs";
import MaterialBox from "../../components/MaterialBox";
import MaterialInput from "../../components/MaterialInput";
import MaterialSelect from "../../components/MasterSelect";
import "../../assest/style/style.css";
import MaterialButton from "../../components/MaterialButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Receipt } from "@mui/icons-material";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import {
  addpayment,
  allCustomer,
  allinvoicenew,
  allUsersDetail,
  allVendor,
  getbilldate$amt,
  getBillno,
  invoicedetails,
} from "../../Api/ApiQuery";
import { createaccounts, createreceipt } from "../../Api/apiMutations";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
const Payment = () => {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [billing, setBilling] = useState([
    {
      type: {},
      bill_no: "",
      bill_date: "yyyy-mm-dd",
      bill_amount: 0,
      outstanding: 0,
      allocating: " ",
      typeStatus: "unchecked",
      bill_noStatud: "unchecked",
    },
  ]);
  const [disabled, setDisabled] = useState(false);
  const [deduction, setDeduction] = useState([
    {
      account: "",
      cost: "",
      amount: "",

      accountStatus: "unchecked",
      cost: "unchecked",
    },
  ]);

  const [paymentdel, setPaymentdel] = useState({
    voucher_name: "",
    against: "",
    Receipt_date: today,
    businees_par: "",
    payment_mode: {
      label: "Accounts Transfer",
      value: "Accounts Transfer",
    },
    name_of_bank: "",
    payment_amount: "",
    cheque_num: "",
    cheque_date: "",
    payment_reference: "",
    remarks: "",
    note:"",
   

    voucher_nameStatus: "unchecked",
    againstStatus: "unchecked",
    Receipt_dateStatus: "unchecked",
    businees_parStatus: "unchecked",
    payment_modeStatus: "success",
    name_of_bankStatus: "unchecked",
    payment_amountStatus: "unchecked",
    cheque_numStatus: "unchecked",
    cheque_dateStatus: "unchecked",
    payment_referenceStatus: "unchecked",
    remarksStatus: "unchecked",
    
  });
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    showCancel: true,
    showConfirm: true,
    customSection: null,
    onConfirm: () => {},
    onCancel: () => {},
  });
  const [vendor, setVendor] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [employe, setEmployee] = useState([]);
  const [invoice, setInvoice] = useState([]);

  const addFunction = () => {
    const temp = [...billing];
    temp.push({
      type: "",
      bill_no: {},
      bill_date: "yyyy-mm-dd",
      bill_amount: 0,
      outstanding: 0,
      allocating: "",
    });
    setBilling(temp);
  };
  const deleteFunction = (ind) => {
    console.log(ind);
    const temp = [...billing];
    temp.splice(ind, 1);
    console.log(temp);
    setBilling(temp);
  };

  const handeltabel = (e, ind, type) => {
    // console.log(e, ind, "sow");
    //  const temp =[]
    const temp = [...billing];
    // const id = e?.target?.selectedOptions[0].dataset.description;
    if (type == "select") {
      console.log(e,"event")
      getinvoicedetail(e, ind);
    } else if (e.target.name == "allocating") {
      temp[ind] = {
        ...temp[ind],
        [e?.target?.name]: e?.target?.value,
        [e?.target?.name + `Status`]: "success",
        ["outstanding"]: +temp[ind]?.bill_amount - +e?.target?.value,
      };
    } else {
      temp[ind] = {
        ...temp[ind],
        [e?.target?.name]: e?.target?.value,
        [e?.target?.name + `Status`]: "success",
      };
    }
    setBilling(temp);
  };

  // console.log(billing, "billing");

  const Against = [
    {
      value: "Payment to Vendor",
      label: "Payment to Vendor",
    },
    {
      value: "Refund to Customer",
      label: "Refund to Customer",
    },
    {
      value: "Payment to Employee",
      label: "Payment to Employee",
    },
  ];
  const payment = [
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "Cheque",
      label: "Cheque",
    },
    {
      value: "Accounts Transfer",
      label: "Accounts Transfer",
    },
  ];
  // onchange handelor

  const handelOnchange = (event, selectEvent) => {
    let temp = { ...paymentdel };

    if (event?.target === undefined && selectEvent) {
      switch (selectEvent.name) {
        case "": {
          if (event) {
            temp[selectEvent?.name + "Status"] = "success";
          } else {
            temp[selectEvent?.name + "Status"] =
              selectEvent.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "businees_par": {
          if (event) {
            temp[selectEvent?.name + "Status"] = "success";
          } else {
            temp[selectEvent?.name + "Status"] =
              selectEvent.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "payment_mode": {
          if (event) {
            temp[selectEvent?.name + "Status"] = "success";
          } else {
            temp[selectEvent?.name + "Status"] =
              selectEvent.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "against": {
          if (event) {
            temp[selectEvent?.name + "Status"] = "success";
          } else {
            temp[selectEvent?.name + "Status"] =
              selectEvent.value === "" ? "unchecked" : "error";
          }
          break;
        }
      }
      temp[selectEvent.name] = event;
    } else {
      switch (event?.target.name) {
        case "payment_amount": {
          if (/^[0-9]{1,7}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "payment_reference": {
          if (/^[a-zA-Z0-9\s]{1,25}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "cheque_num": {
          if (/^[0-9]{1,7}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "cheque_date": {
          if (event?.target.value) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "remarks": {
          if (/^[a-zA-Z0-9\s]{1,250}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "note": {
          if (/^[a-zA-Z0-9\s]{1,250}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
      }
      temp[event?.target.name] = event?.target.value;
    }
    setPaymentdel(temp);
  };

  console.log(paymentdel, "paymentdel");

  const getallvendor = () => {
    const response = request(BASEURL, allVendor)
      .then((res) => {
        setVendor(res?.allVendor);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getallCustomer = () => {
    const response = request(BASEURL, allCustomer)
      .then((res) => {
        // console.log(res);
        setCustomer(res?.allCustomer);
        // setVendor(res?.allVendor);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getallEmplu = () => {
    const response = request(BASEURL, allUsersDetail)
      .then((res) => {
        // console.log(res);
        setEmployee(res?.allUsersDetail);
        // setVendor(res?.allVendor);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getallinvoice = () => {
    const response = request(BASEURL, getBillno)
      .then((res) => {
        console.log(res);
        // setEmployee(res?.allUsersDetail);
        const data = res?.allPurchase.filter((item) =>
          billing?.every((itm) => itm?.bill_no?.value !== item?.id)
        );
        console.log(data, "data");
        setInvoice(data);
        // setInvoice(res?.allInvoice);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getinvoicedetail = (id, ind) => {
    console.log("trigering type select")
    console.log(id);

    const response = request(BASEURL, getbilldate$amt, {
      id: id?.value,
    })
      .then((res) => {
        console.log(res,"dateres")
        console.log(res?.purchase
        );
        const data = res?.purchase
        ;
        const temp = [...billing];

        temp[ind] = {
          ...temp[ind],
          bill_noStatud: "success",
          bill_no: id,
          bill_date: data?.orderDate,
          bill_amount: data?.totalamount,
        };
        setBilling(temp);
        console.log("billing after date",billing)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getallvendor();
    getallCustomer();
    getallEmplu();
    getallinvoice();
  }, []);
  useEffect(() => {
    getallinvoice();
  }, [billing]);

  const type = [
    {
      value: "Purchase Order",
      label: "Purchase Order",
    },
    {
      value: "Purchase Bill",
      label: "Purchase Bill",
    },
    {
      value: "Bills Outstanding",
      label: "Bills Outstanding",
    },
    {
      value: "Debit Note",
      label: "Debit Note",
    },
  ];

  const customerType = [
    {
      value: "Sales  Invoice",
      label: "Sales  Invoice",
    },
    {
      value: "Performa Invoice",
      label: "Performa Invoice",
    },
    {
      value: "Invoice Outstanding",
      label: "Invoice Outstanding",
    },
    {
      value: "Credit Note",
      label: "Credit Note",
    },
  ];

  const employeType = [
    {
      value: "Employees",
      label: "Employees",
    },
  ];
  const creditadd = billing.reduce((total, num) => total + +num?.allocating, 0);
  const disabledHandel = () => {
    let disabledstatus = false;
    const ledgerstatus = billing.every((obj) => obj?.typeStatus == "success");
    const ledgerbill = billing.every((obj) => obj?.bill_noStatud == "success");
    if (
      // billing?.typeStatus == "success" &&
      // billing?.bill_noStatud == "success" &&
      // deduction?.accountStatus == "success" &&
      // deduction?.cost == "success" &&
      paymentdel?.againstStatus == "success" &&
      paymentdel?.businees_parStatus == "success" &&
      paymentdel?.payment_modeStatus == "success" &&
      paymentdel?.payment_amountStatus == "success" &&
      paymentdel?.payment_amount == creditadd &&
      ledgerstatus &&
      ledgerbill
    ) {
      disabledstatus = false;
    } else {
      disabledstatus = true;
    }

    return disabledstatus;
  };
  console.log(billing);
  const userdetails = localStorage.getItem("user");
  const userid = JSON.parse(userdetails);
  const manual = [
    {
      user: userid?.id,
      ledger: "77",
      debit: creditadd,
      credit: "0",
      voucherDate: today,
      voucherType: "Payment",
    },
    {
      user: userid?.id,
      ledger: "78",
      debit: "0",
      credit: creditadd,
      voucherDate: today,
      voucherType: "Payment",
    },
  ];

  const addreceipt = () => {
    setConfirmationDialog({
      open: true,
      title: "Confirmation",
      message: "Are you sure do you want to Submit your details.",
      showCancel: true,
      showConfirm: true,
      onConfirm: () => {
        const userdetails = localStorage.getItem("user");
        const userid = JSON.parse(userdetails);
        const decodedString = atob(userid?.id);
        const originalNumber = decodedString.split(":")[1];
        const value = {
          userId: originalNumber,
          against: paymentdel?.against?.value,
          businessPartner: paymentdel?.businees_par?.value,
          paymentMode: paymentdel?.payment_mode?.value,
          documentNumber: "DOC12345",
          postingDate:paymentdel?.Receipt_date,
          nameofbankorcash:
            paymentdel?.payment_mode?.value == "Cash" ? "11200" : "11300",
            paymentAmount: paymentdel?.payment_amount,
          reference: paymentdel.payment_reference,
          // receiptDate: paymentdel?.Receipt_date,
          narration: paymentdel?.remarks,
          notes: paymentdel?.note,
          billDetails: billing?.map((item) => ({
            typeofagainst: item?.type,
            billNumber: item?.bill_no.label,
            billDate: item?.bill_date,
            billAmount: item?.bill_amount,
            allocated: item?.allocating,
            outstanding: item?.outstanding.toString(),
            total:creditadd,
            
          })),
        };
        if (paymentdel?.payment_mode?.label == "Cheque") {
          value.chequeNumber = paymentdel?.cheque_num;
          value.chequeDate = paymentdel?.cheque_date;
        } else {
          value.chequeNumber = null;
          value.chequeDate = null;
        }

        console.log("value",value)
        console.log("on submit billing",billing)
        const res = request(BASEURL, addpayment, {
          input: value,
        })
          .then((res) => {
            console.log(res);
            setConfirmationDialog({
              open: true,
              title: "Success message",
              message: "Successfully Receipt Added",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
            navigate("/payment");
          })
          .catch((err) => {
            setConfirmationDialog({
              open: true,
              title: "Error message",
              message:
                "We are unable to fetch the required data at this time. Please try again later. If the problem persists, please contact support.",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
            console.log(err);
          });

        const response = request(BASEURL, createaccounts, {
          input: manual,
        })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel: () => {
        setConfirmationDialog({
          ...confirmationDialog,
          open: false,
        });
      },
    });
  };

  const exceptThisSymbols = [];

  for (let i = 65; i <= 90; i++) {
    exceptThisSymbols.push(String.fromCharCode(i));
  }

  for (let i = 97; i <= 122; i++) {
    exceptThisSymbols.push(String.fromCharCode(i));
  }

  const specialCharacters = `~!@#$%^&*()_+=[]{}|;:'",<>?/\\\`-`.split("");
  exceptThisSymbols.push(...specialCharacters);

  exceptThisSymbols.push("e", "E", "+", "-");

  console.log(exceptThisSymbols);
  return (
    <DashboardLayout>
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          showCancel={confirmationDialog.showCancel}
          showConfirm={confirmationDialog.showConfirm}
          customSection={confirmationDialog.customSection}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={confirmationDialog.onCancel}
          done={confirmationDialog.done}
          back={confirmationDialog.back}
        />
      )}
      <Card>
        <CardHeader
          title={
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column" spacing={0}>
                  <MaterialTypography variant="h5" fontWeight="medium">
                    Add Payment
                  </MaterialTypography>
                </Stack>
              </Stack>
            </>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Payment Details
            </MaterialTypography>
          </Stack>
          <MaterialBox>
            <Grid container spacing={2}>
              {/* <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Voucher Name <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="voucher_name"
                  placeholder="Enter voucher name"
                  value={paymentdel.voucher_name}
                  onChange={handelOnchange}
                  error={
                    paymentdel.voucher_nameStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.voucher_nameStatus === "success" ? true : false
                  }
                  // disabled={true}
                />
              </Grid> */}
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Against <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="against"
                  value={paymentdel.against}
                  defaultValue={{ label: "Please select" }}
                  customStyles={{ optionPadding: 20 }}
                  onChange={handelOnchange}
                  options={Against?.map((value) => {
                    return {
                      value: value?.value,
                      label: value?.label,
                    };
                  })}
                  error={paymentdel?.againstStatus === "error" ? true : false}
                  success={
                    paymentdel?.againstStatus === "success" ? true : false
                  }
                  // isDisabled={togglePage?.view === true ? true : false}
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Payment Date <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  type="date"
                  name="Receipt_date"
                  placeholder="Enter voucher name"
                  value={paymentdel.Receipt_date}
                  onChange={handelOnchange}
                  error={
                    paymentdel.Receipt_dateStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.Receipt_dateStatus === "success" ? true : false
                  }
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Business Partner <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="businees_par"
                  value={paymentdel?.businees_par}
                  defaultValue={{ label: "Please Select" }}
                  customStyles={{ optionPadding: 20 }}
                  onChange={handelOnchange}
                  options={
                    paymentdel?.against?.value == "Payment to Vendor"
                      ? vendor?.map((value) => {
                          return {
                            value: value?.id,
                            label: value?.legalName,
                          };
                        })
                      : paymentdel?.against?.value == "Refund to Customer"
                      ? customer?.map((value) => {
                          return {
                            value: value?.id,
                            label: value?.legalName,
                          };
                        })
                      : paymentdel?.against?.value == "Payment to Employee"
                      ? employe?.map((value) => {
                          return {
                            value: value?.id,
                            label: value?.legalName,
                          };
                        })
                      : []
                  }
                  error={
                    paymentdel?.businees_parStatus === "error" ? true : false
                  }
                  success={
                    paymentdel?.businees_parStatus === "success" ? true : false
                  }
                  // isDisabled={togglePage?.view === true ? true : false}
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Payment Mode <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="payment_mode"
                  value={paymentdel?.payment_mode}
                  defaultValue={{ label: "Please Select" }}
                  customStyles={{ optionPadding: 20 }}
                  onChange={handelOnchange}
                  options={payment?.map((value) => {
                    return {
                      value: value?.value,
                      label: value?.label,
                    };
                  })}
                  error={
                    paymentdel.payment_modeStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.payment_modeStatus === "success" ? true : false
                  }
                  // isDisabled={togglePage?.view === true ? true : false}
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Name of the Bank/Cash{" "}
                    <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="name_of_bank"
                  placeholder="Enter Name of the Bank/Cash"
                  value={
                    paymentdel?.payment_mode?.value == "Cash"
                      ? "11200"
                      : "11300"
                  }
                  onChange={handelOnchange}
                  // onChange={handleleadandProspectData}
                  error={
                    paymentdel.name_of_bankStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.name_of_bankStatus === "success" ? true : false
                  }
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Payment Amount <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="payment_amount"
                  type="text"
                  placeholder="Enter payment Amount"
                  value={paymentdel.payment_amount}
                  onChange={handelOnchange}
                  error={
                    paymentdel.payment_amountStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.payment_amountStatus === "success" ? true : false
                  }
                  inputProps={{
                    maxlength: "8",
                  }}
                  onKeyDown={(e) =>
                    exceptThisSymbols.includes(e.key) && e.preventDefault()
                  }
                />
              </Grid>
              {paymentdel?.payment_mode?.label == "Cheque" && (
                <>
                  <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                    <MaterialBox lineHeight={0} display="inline-block">
                      <MaterialTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Cheque Number <span style={{ color: "red" }}>*</span>
                      </MaterialTypography>
                    </MaterialBox>
                    <MaterialInput
                      name="cheque_num"
                      placeholder="Enter Cheque Number"
                      value={paymentdel.cheque_num}
                      onChange={handelOnchange}
                      error={
                        paymentdel.cheque_numStatus === "error" ? true : false
                      }
                      success={
                        paymentdel.cheque_numStatus === "success" ? true : false
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                    <MaterialBox lineHeight={0} display="inline-block">
                      <MaterialTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Cheque Date <span style={{ color: "red" }}>*</span>
                      </MaterialTypography>
                    </MaterialBox>
                    <MaterialInput
                      type="date"
                      name="cheque_date"
                      placeholder="Enter voucher name"
                      value={paymentdel.cheque_date}
                      onChange={handelOnchange}
                      error={
                        paymentdel.cheque_dateStatus === "error" ? true : false
                      }
                      success={
                        paymentdel.cheque_dateStatus === "success"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Payment Reference
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="payment_reference"
                  placeholder="Enter Payment Reference"
                  value={paymentdel.payment_reference}
                  // onChange={handleleadandProspectData}
                  onChange={handelOnchange}
                  error={
                    paymentdel.payment_referenceStatus === "error"
                      ? true
                      : false
                  }
                  success={
                    paymentdel.payment_referenceStatus === "success"
                      ? true
                      : false
                  }
                />
              </Grid>
            </Grid>
          </MaterialBox>
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction="row" spacing={1}>
              <BsFillPersonVcardFill style={{ fontSize: 20 }} />
              <MaterialTypography
                component="label"
                variant="body2"
                fontWeight="bold"
              >
                Bill Details
              </MaterialTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <MaterialButton
                sx={{ m: 2 }}
                variant="gradient"
                color="dark"
                Icon={<AddIcon />}
                onClick={() => {
                  addFunction();
                }}
              >
                Add Billing
              </MaterialButton>
            </Stack>
          </div>

          <MaterialBox>
            <div className="tabel-target">
              <table
                border="1"
                cellPadding="10"
                cellSpacing="0"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "none",
                }}
              >
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Bill No</th>
                    <th>Bill Date</th>
                    <th>Bill Amount</th>
                    <th>Outstanding Amount</th>
                    <th>Allocating Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {billing.map((item, ind) => {
                    return (
                      <tr>
                        <td>
                          <select
                            style={{
                              width: "120px",
                              padding: "10px",
                              border: `1px solid ${
                                item?.typeStatus == "success"
                                  ? "#8BC34A"
                                  : item?.typeStatus == "error"
                                  ? "#ea0606"
                                  : "gray"
                              }`,
                              borderRadius: "5px",
                              outline: "0",
                            }}
                            name="type"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={item?.type}
                            error={item.typeStatus === "error" ? true : false}
                            success={
                              item?.typeStatus === "success" ? true : false
                            }
                          >
                            <option value={""}>Select Type</option>
                            {paymentdel?.against?.value ==
                            "Payment to Vendor" ? (
                              type?.map((value) => {
                                return (
                                  <option
                                    value={value?.value}
                                    style={{
                                      width: "120px",
                                      padding: "10px",
                                      border: `2px solid gray`,
                                      borderRadius: "5px",
                                      outline: "0",
                                    }}
                                  >
                                    {value?.label}
                                  </option>
                                );
                              })
                            ) : paymentdel?.against?.value ==
                              "Refund to Customer" ? (
                              customerType?.map((value) => {
                                return (
                                  <option
                                    value={value?.value}
                                    style={{
                                      width: "120px",
                                      padding: "10px",
                                      border: `2px solid gray`,
                                      borderRadius: "5px",
                                      outline: "0",
                                    }}
                                  >
                                    {value?.label}
                                  </option>
                                );
                              })
                            ) : paymentdel?.against?.value ==
                              "Payment to Employee" ? (
                              employeType?.map((value) => {
                                return (
                                  <option
                                    value={value?.value}
                                    style={{
                                      width: "120px",
                                      padding: "10px",
                                      border: `2px solid gray`,
                                      borderRadius: "5px",
                                      outline: "0",
                                    }}
                                  >
                                    {value?.label}
                                  </option>
                                );
                              })
                            ) : (
                              <option
                                style={{
                                  width: "120px",
                                  padding: "10px",
                                  border: `2px solid gray`,
                                  borderRadius: "5px",
                                  outline: "0",
                                  backgroundColor: "white",
                                }}
                                value={""}
                              >
                                No Options
                              </option>
                            )}
                          </select>
                        </td>
                        <td style={{ width: "230px" }}>
                          {/* <select
                            style={{
                              width: "120px",
                              padding: "10px",
                              border: `1px solid ${
                                item?.bill_noStatud == "success"
                                  ? "#8BC34A"
                                  : item?.bill_noStatud == "error"
                                  ? "#ea0606"
                                  : "gray"
                              }`,
                              borderRadius: "5px",
                              outline: "0",
                            }}
                            name="bill_no"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={item?.bill_no}
                            error={
                              item?.bill_noStatud === "error" ? true : false
                            }
                            success={
                              item?.bill_noStatud === "success" ? true : false
                            }
                          >
                            
                            <option value={""}>Select Bill</option>
                            {item?.type == "Sales  Invoice" ? (
                              invoice?.map((value) => {
                                return (
                                  <option
                                    value={value?.id}
                                   
                                    style={{
                                      width: "120px",
                                      padding: "10px",
                                      border: `2px solid gray`,
                                      borderRadius: "5px",
                                      outline: "0",
                                    }}
                                  >
                                    {value?.invoiceId}
                                  </option>
                                );
                              })
                            ) : (
                              <option
                                style={{
                                  width: "120px",
                                  padding: "10px",
                                  border: `2px solid gray`,
                                  borderRadius: "5px",
                                  outline: "0",
                                  backgroundColor: "white",
                                }}
                                value={""}
                              >
                                No Option
                              </option>
                            )}
                          </select> */}
                          <MaterialSelect
                            name="bill_no"
                            onChange={(e) => handeltabel(e, ind, "select")}
                            value={item?.bill_no}
                            // optionPadding={{0}}
                            customStyles={{ optionPadding: 20 }}
                            defaultValue={{ label: "Please select" }}
                            options={invoice.map((value) => ({
                              value: value.id,
                              label: value.purchaseOrderid,
                            }))}
                            error={
                              item?.bill_noStatud === "error" ? true : false
                            }
                            success={
                              item?.bill_noStatud === "success"
                                ? true
                                : false
                            }
                          />
                          {/* <select
                            style={{
                              width: "120px",
                              padding: "10px",
                              border: `1px solid ${
                                item?.bill_noStatud === "success"
                                  ? "#8BC34A"
                                  : item?.bill_noStatud === "error"
                                  ? "#ea0606"
                                  : "gray"
                              }`,
                              borderRadius: "5px",
                              outline: "0",
                            }}
                            name="bill_no"
                            onChange={(e) => {
                              handeltabel(e, ind); // Handle the dropdown change
                            }}
                            value={item?.bill_no || ""} // Bind to the selected value
                          >
                            <option value="">Select Bill</option>
                            {item?.type === "Sales Invoice" ? (
                              invoice?.map((value) => (
                                <option
                                  key={value?.id}
                                  value={value?.id}
                                  data-description={value?.id} // Pass additional data if needed
                                  style={{
                                    width: "120px",
                                    padding: "10px",
                                    border: `2px solid gray`,
                                    borderRadius: "5px",
                                    outline: "0",
                                  }}
                                >
                                  {value?.invoiceId}
                                </option>
                              ))
                            ) : (
                              <option
                                style={{
                                  width: "120px",
                                  padding: "10px",
                                  border: `2px solid gray`,
                                  borderRadius: "5px",
                                  outline: "0",
                                  backgroundColor: "white",
                                }}
                                value=""
                              >
                                No Option
                              </option>
                            )}
                          </select> */}
                        </td>
                        <td style={{ width: "170px", color: "black" }}>
                          {item?.bill_date}
                        </td>
                        <td style={{ width: "150px", color: "black" }}>
                          {item?.bill_amount}
                        </td>
                        <td style={{ width: "150px", color: "black" }}>
                          {item?.outstanding}
                        </td>
                        <td style={{ width: "200px" }}>
                          <MaterialInput
                            name="allocating"
                            type="text"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={item?.allocating}
                            inputProps={{
                              maxlength: "8",
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                          ></MaterialInput>
                        </td>
                        <td>
                          <DeleteIcon
                            sx={{
                              transform: "scale(1.5)",
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => {
                              deleteFunction(ind);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* <tbody>{renderRows(data)}</tbody> */}
              </table>
            </div>
          </MaterialBox>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p style={{ color: "#3F51B5d4", fontSize: "18px" }}>
              Total Amount:
              <span style={{ color: "black" }}>{`₹${creditadd}`}</span>
            </p>
          </div>

          {/* <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Deduction / Loss
            </MaterialTypography>
          </Stack> */}
          {/* <MaterialBox>
            <div className="tabel-target">
              <table
                border="1"
                cellPadding="10"
                cellSpacing="0"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "none",
                }}
              >
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Cost Center</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {deduction.map((item) => {
                    return (
                      <tr>
                        <td>
                          <MaterialSelect></MaterialSelect>
                        </td>
                        <td>
                          <MaterialSelect></MaterialSelect>
                        </td>
                        <td>
                          <MaterialInput></MaterialInput>
                        </td>
                        <td>
                          <AddIcon
                            sx={{
                              transform: "scale(1.5)",
                              // marginRight: "35px",
                              cursor: "pointer",
                              color: "green",
                            }}
                          />
                          <DeleteIcon
                            sx={{
                              transform: "scale(1.5)",
                              marginLeft: "35px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* <tbody>{renderRows(data)}</tbody> */}
          {/* </table>
            </div>
          </MaterialBox>  */}
          {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p
              style={{
                color: "#3F51B5d4",
                fontSize: "18px",
                marginRight: "10px",
              }}
            >
              Total Amount:<span style={{ color: "black" }}>{`₹7578`}</span>
            </p>
          </div> */}
          <Divider />
          <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Additional Informations
            </MaterialTypography>
          </Stack>
          <Grid container spacing={2}>
            <Grid size={{ xs: 5, sm: 3, lg: 6 }}>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  paddingLeft: "20px",
                  marginTop: "20px",
                }}
              > */}
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Notes{" "}
                </MaterialTypography>
              </MaterialBox>
              <textarea
                value={paymentdel?.note}
                onChange={(e) => handelOnchange(e)}
                name="note"
                style={{
                  minHeight: "120px",
                  maxHeight: "120px",
                  maxWidth: "100%",
                  minWidth: "100%",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  padding: "15px",
                  marginTop: "10px",
                  borderColor: `${
                    paymentdel?.noteStatus == "success"
                      ? "#32f738 "
                      : paymentdel?.noteStatus == "error"
                      ? "red"
                      : "gray"
                  }`,
                  outline: 0,
                }}
                placeholder="Additional Informations"
                // onChange={(e) => setAddition(e.target.value)}
              />
              {/* </div> */}
            </Grid>
            <Grid size={{ xs: 5, sm: 3, lg: 6 }}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Remarks
                </MaterialTypography>
              </MaterialBox>
              {/* <MaterialInput
                  name="remarks"
                  placeholder="Enter Remarks"
                  value={paymentdel.remarks}
                  // onChange={handleleadandProspectData}
                  onChange={handelOnchange
                  error={paymentdel.remarksStatus === "error" ? true : false}
                  success={
                    paymentdel.remarksStatus === "success" ? true : false
                  }
                /> */}
              <textarea
                name="remarks"
                placeholder="Enter Remarks"
                value={paymentdel.remarks}
                // onChange={handleleadandProspectData}
                onChange={(e) => handelOnchange(e)}
                style={{
                  minHeight: "120px",
                  maxHeight: "120px",
                  maxWidth: "100%",
                  minWidth: "100%",
                  border: "1px solid gray",
                  borderRadius: "5px",
                  padding: "15px",
                  marginTop: "10px",
                  borderColor: `${
                    paymentdel?.remarksStatus == "success"
                      ? "#32f738 "
                      : paymentdel?.remarksStatus == "error"
                      ? "red"
                      : "gray"
                  }`,
                  outline: 0,
                }}
              />
            </Grid>
            {/* <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Attachment{" "}
                </MaterialTypography>
              </MaterialBox>
            </Grid> */}
          </Grid>
        </CardContent>
        <MaterialBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <MaterialButton
            variant="gradient"
            color="dark"
            // onClick={handleDiscardButton}
          >
            Cancel
          </MaterialButton>
          <MaterialButton
            sx={{ m: 2 }}
            variant="gradient"
            color="dark"
            disabled={disabledHandel()}
            onClick={addreceipt}
          >
            Submit
          </MaterialButton>
        </MaterialBox>
      </Card>
    </DashboardLayout>
  );
};

export default Payment;

