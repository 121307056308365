import React from "react";
import DashboardLayout from "../layoutcontainers/DashboardLayout";
import PaperCard from "./PaperCard";
import "./papercard.css";

const DashBoard = () => {
  return (
    <DashboardLayout>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "50%", marginRight: "10px" }}>
          {" "}
          <PaperCard title={"Total Receivables"}>
            <div>
              <p style={{ fontSize: "12px", color: "grey" }}>
                Total unpaid invoices<span className="blur-text" style={{marginLeft:"10px"}}>6789999998</span>
              </p>
              <div className="input-width">
                <div
                  style={{ width: "40%", background: "blue", height: "100%" }}
                ></div>
              </div>
            </div>
          </PaperCard>
        </div>
        <div style={{ width: "50%", marginLeft: "10px" }}>
          {" "}
          <PaperCard title={"Total Payables"}>
            <div>
              <p style={{ fontSize: "12px", color: "grey" }}>
                Total unpaid Bills<span className="blur-text"  style={{marginLeft:"10px"}}>6789999998</span>
              </p>
              <div className="input-width">
                <div
                  style={{ width: "0%", background: "blue", height: "100%" }}
                ></div>
              </div>
            </div>
          </PaperCard>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashBoard;
