import { useEffect, useState } from "react";

// @mui material components
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useNavigate } from "react-router-dom";
import SigninLayout from "../../../layout/SigninLayout";
import bgImage from "../../../assest/LoginImage.jpg";
import BASEURL from "../../Utils/Baseurl";
import request, { gql } from "graphql-request";
import MaterialBox from "../../../components/MaterialBox";
import MaterialButton from "../../../components/MaterialButton";
import MaterialInput from "../../../components/MaterialInput";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LOGIN } from "../../../Api/apiMutations";
import { useLoading } from "../../../context/LoadingContext";

function Signin() {
  const navigate = useNavigate();

  const [pageState, setPageState] = useState({
    loading: false,
    error: false,
    message: "",
    state: "init",
  });

  const [signInData, setSignInData] = useState({
    email: "",
    password: null,
    emailStatus: "unchecked",
    passwordStatus: "unchecked",
  });

  const [stage] = useState("sign-in");
  const { circleLoading, setCirleLoading } = useLoading();

  const [viewPassword, setViewPassword] = useState(false);

  useEffect(() => {
    setPageState({
      ...pageState,
      state: "default",
    });
  }, []);

  const handlePasswordVisibility = () => {
    setViewPassword(!viewPassword);
  };

  /**
   * Handles the change event for form inputs.
   * @param {Object} event - The event object generated by the change event.
   * @returns {void}
   */
  const handleChange = (event) => {
    const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,6}$/g);
    //lowercase regex expression
    const lowerCaseRegex = new RegExp("^(?=.*[a-z])");
    //uppercase regex expression
    const upperCaseRegex = new RegExp("^(?=.*[A-Z])");
    //number regex expression
    const numberRegex = new RegExp("^(?=.*[0-9])");
    //special character regex expression
    const specialCharacterRegex = new RegExp("^(?=.*[@$!%*?&])");
    //length regex expression
    const lengthRegex = new RegExp("^([\\s\\S]{3,10})$");

    let signInState = { ...signInData };
    if (event.target.name === "email") {
      if (emailRegex.test(event.target.value) === true) {
        signInState.emailStatus = "success";
      } else {
        signInState.emailStatus = "error";
      }
      signInState[event.target.name] = event.target.value;
    } else if (event.target.name === "password") {
      if (
        // lowerCaseRegex.test(event.target.value) &&
        // upperCaseRegex.test(event.target.value) &&
        // numberRegex.test(event.target.value) &&
        // specialCharacterRegex.test(event.target.value) &&
        lengthRegex.test(event.target.value) === true
      ) {
        signInState.passwordStatus = "success";
      } else {
        signInState.passwordStatus = "error";
      }
      signInState[event.target.name] = event.target.value;
    }
    setSignInData(signInState);
  };

  const loginHandle = () => {
    setCirleLoading(true);
    request(BASEURL, LOGIN, {
      email: signInData?.email,
      password: signInData?.password,
    })
      .then((res) => {
        console.log(res?.tokenAuth);
        localStorage.setItem("token", JSON.stringify(res?.tokenAuth?.token));
        localStorage.setItem("user", JSON.stringify(res?.tokenAuth?.user));
        localStorage.setItem(
          "refreshToken",
          JSON.stringify(res?.tokenAuth?.refreshToken)
        );
        if (res?.tokenAuth?.success==true) {
          navigate("/dashboard");
        }else{
          setPageState({
            ...pageState,
            loading: false,
            error: true,
            title: "Error message",
            message: "Please check entered details",
            state: "default",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setPageState({
          ...pageState,
          loading: false,
          error: true,
          title: "Error message",
          message: "Please check entered details",
          state: "default",
        });
      })
      .finally(() => setCirleLoading(false));
  };
  // const handleSignIn = async (value) => {
  //     // setLoading(true)
  //     const { email, password } = signInData
  //     console.log('SigninData', email, password)
  //     try {
  //         request(BASEURL, LOGIN, {
  //             email: email,
  //             password: password
  //         })
  //             .then(response => {
  //                 console.log("response-------->", response.tokenAuth, response.tokenAuth.success);
  //                 if (response.tokenAuth.success) {
  //                     // navigate('/admin/dashboard');
  //                     // updatingUser(response)
  //                     // sendDataToParent(true)
  //                     // saveAuth(response)
  //                     // setCurrentUser(response)
  //                     setPageState({
  //                         ...pageState,
  //                         loading: false,
  //                         error: true,
  //                         title: "Success message",
  //                         message: "Successfully updated",
  //                         state: 'default'
  //                     })
  //                     console.log(response)
  //                     localStorage.setItem("vividobotsHRM", JSON.stringify(response))
  //                 } else {
  //                     localStorage.setItem("vividobotsHRM", "undefined")
  //                     setPageState({
  //                         ...pageState,
  //                         loading: false,
  //                         error: true,
  //                         title: "Error message",
  //                         message: "Please check entered details",
  //                         state: 'default'
  //                     })
  //                 }
  //             })
  //             .catch(error => {
  //                 console.error(error)
  //                 localStorage.setItem("vividobotsDetails", "undefined")
  //                 setPageState({
  //                     ...pageState,
  //                     loading: false,
  //                     error: true,
  //                     title: "Error message",
  //                     message: "Please check entered details",
  //                     state: 'default'
  //                 })
  //             })
  //     } catch (error) {
  //         console.error(error)
  //         setPageState({
  //             ...pageState,
  //             loading: false,
  //             error: true,
  //             title: "Error message",
  //             message: "Please check entered details",
  //             state: 'default'
  //         })
  //     }
  // }
  // const loginHandle = () => {
  //   // setLoading(true)
  //   if (
  //     signInData?.emailStatus == "success" &&
  //     signInData?.passwordStatus == "success"
  //   ) {
  //     //   const formdata = {
  //     //     identifier: signInData?.email,
  //     //     password: signInData?.password,
  //     //   };

  //     //   loginapi(formdata)
  //     //     .unwrap()
  //     //     .then((res) => {
  //     //       localStorage.setItem("token", res?.token);
  //     //         navigate("/dashboard")
  //     //       setPageState({
  //     //         ...pageState,
  //     //         loading: false,
  //     //         error: true,
  //     //         title: "Success message",
  //     //         message: "Successfully updated",
  //     //         state: "default",
  //     //       });
  //     //     })
  //     //     .catch((err) => {
  //     //       console.log(err);
  //     //       setPageState({
  //     //         ...pageState,
  //     //         loading: false,
  //     //         error: true,
  //     //         title: "Error message",
  //     //         message: "Please check entered details",
  //     //         state: "default",
  //     //       });
  //     //     })
  //     //     .finally(()=>{
  //     //       // setLoading(false)
  //     //     })
  //     if (
  //       signInData?.email == "admin@gmail.com" &&
  //       signInData?.password == "Admin@123"
  //     ) {
  //       navigate("/dashboard");
  //       setPageState({
  //         ...pageState,
  //         loading: false,
  //         error: true,
  //         title: "Success message",
  //         message: "Successfully updated",
  //         state: "default",
  //       });
  //     } else {
  //       setPageState({
  //         ...pageState,
  //         loading: false,
  //         error: true,
  //         title: "Error message",
  //         message: "Please check entered details",
  //         state: "default",
  //       });
  //     }
  //   } else {
  //     setSignInData?.emailStatus("error");
  //     setSignInData?.passwordStatus("error");
  //   }
  // };
  const keydownHandler = (event) => {
    console.log("hh", event);
    if (event.key === "Enter") {
      loginHandle();
    }
  };
  return (
    <SigninLayout
      // title="KEELS"
      description="Enter your email and password to sign in"
      illustration={bgImage}
    >
      {pageState.error === true && (
        <Dialog maxWidth="sm" open={pageState.error} fullWidth>
          <DialogTitle>
            <Typography variant="body1">{pageState.title}</Typography>
          </DialogTitle>
          <DialogContent variant="body2">{pageState.message}</DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="text"
              onClick={() =>
                setPageState({
                  ...pageState,
                  loading: false,
                  error: false,
                  title: "",
                  message: "",
                  state: "default",
                })
              }
            >
              close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {pageState.state === "default" && (
        <MaterialBox component="form" role="form">
          {stage === "sign-in" && (
            <>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 12 }}>
                  <MaterialInput
                    onKeyDown={keydownHandler}
                    fullWidth
                    placeholder="Email"
                    name="email"
                    variant="outlined"
                    value={signInData.email}
                    onChange={handleChange}
                    error={signInData.emailStatus === "error" ? true : false}
                    success={
                      signInData.emailStatus === "success" ? true : false
                    }
                  />
                  {signInData.emailStatus === "error" ? (
                    <FormControl error>
                      <FormHelperText>
                        Please enter valid email address
                      </FormHelperText>
                    </FormControl>
                  ) : null}
                </Grid>
                <Grid size={{ xs: 12, sm: 12 }}>
                  <MaterialInput
                    onKeyDown={keydownHandler}
                    fullWidth
                    placeholder="Password"
                    name="password"
                    type={viewPassword ? "text" : "password"}
                    variant="outlined"
                    value={signInData.password}
                    onChange={handleChange}
                    error={signInData.passwordStatus === "error" ? true : false}
                    success={
                      signInData.passwordStatus === "success" ? true : false
                    }
                    inputProps={{
                      maxlength: "10",
                    }}
                    icon={
                      viewPassword
                        ? {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityIcon
                                  onClick={handlePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                        : {
                            direction: "right",
                            component: (
                              <Icon color="action" fontSize="small">
                                <VisibilityOffIcon
                                  onClick={handlePasswordVisibility}
                                  style={{ cursor: "pointer" }}
                                />
                              </Icon>
                            ),
                          }
                    }
                  />
                  {signInData.passwordStatus === "error" ? (
                    <FormControl error>
                      <FormHelperText>
                        {/* Your password must be minimum 8 characters long with
                        lower case, upper case, number and special character. */}
                        Please Enter valid Password
                      </FormHelperText>
                    </FormControl>
                  ) : null}
                </Grid>
              </Grid>
              <MaterialBox display="flex" justifyContent="flex-end" my={2}>
                <MaterialButton
                  variant="text"
                  color="black"
                  onClick={() => {
                    //TODO: Add forget password functionality
                  }}
                  sx={{ padding: 0 }}
                  disabled={signInData.emailStatus === "success" ? false : true}
                >
                  Forgot Password ?
                </MaterialButton>
              </MaterialBox>
              <MaterialBox my={2}>
                <MaterialButton
                  variant="contained"
                  color="dark"
                  fullWidth
                  onClick={() => {
                    loginHandle();
                  }}
                  disabled={
                    signInData.emailStatus === "success" &&
                    signInData.passwordStatus === "success"
                      ? false
                      : true
                  }
                >
                  Sign in
                </MaterialButton>
              </MaterialBox>
              {/* <MaterialBox position="relative" >
                                        <Divider />
                                        <MaterialBox
                                            position="absolute"
                                            top="50%"
                                            left="50%"
                                            px={1.5}
                                            lineHeight={1}
                                            sx={{ transform: "translate(-50%, -60%)" }}
                                        >
                                            <MaterialTypography variant="button" fontWeight="medium" color="black">
                                                or
                                            </MaterialTypography>
                                        </MaterialBox>
                                    </MaterialBox>
                                    <MaterialBox>
                                        <MaterialButton
                                            variant="contained"
                                            color="dark"
                                            size="large"
                                            fullWidth
                                            onClick={() => {
                                                navigate('/')
                                            }}
                                        // disabled={
                                        //     phoneStatus.status === 'success' ? false : true
                                        // }
                                        // loading={loading}
                                        >
                                            sign up
                                        </MaterialButton>
                                    </MaterialBox> */}
            </>
          )}
          {stage === "forget-password" && (
            <>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 12 }}>
                  <MaterialInput
                    fullWidth
                    placeholder="Email"
                    name="email"
                    variant="outlined"
                    value={signInData.email}
                    onChange={handleChange}
                    error={signInData.emailStatus === "error" ? true : false}
                    success={
                      signInData.emailStatus === "success" ? true : false
                    }
                  />
                </Grid>
              </Grid>
              <MaterialBox my={2}>
                <MaterialButton
                  variant="contained"
                  color="dark"
                  fullWidth
                  onClick={() => {
                    loginHandle();
                  }}
                  disabled={
                    signInData.emailStatus === "success" &&
                    signInData.passwordStatus === "success"
                      ? false
                      : true
                  }
                >
                  Sign in
                </MaterialButton>
              </MaterialBox>
            </>
          )}
        </MaterialBox>
      )}
    </SigninLayout>
  );
}

export default Signin;
