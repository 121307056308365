import { useContext } from "react";
import { useState, useEffect } from "react";
import { createContext } from "react";

export const MyLoadingContext = createContext();

export default function LoadingContext({ children }) {
  const [circleLoading, setCirleLoading] = useState(false);

  return (
    <MyLoadingContext.Provider
      value={{
        circleLoading,
        setCirleLoading,
      }}
    >
      {children}
    </MyLoadingContext.Provider>
  );
}

export const useLoading = () => {
  return useContext(MyLoadingContext);
};
